<table class="table table-xxs table-hover">
    <tbody>
        <tr *ngFor="let file of data.queue; let fst = first; let lst = last">
            <td
                class="border-bottom border-bottom-default"
                [ngClass]="{
                    'no-border-top': fst,
                    'no-border-bottom': lst && !data.allowEdit && !data.allowPartiallyEdit
                }"
            >
                <ng-container *ngIf="file._id">
                    <span *ngIf="!accessService.hasAccess([permissions.File_Operations]) || !allowFileAccess(file)">
                        {{ file?.file?.name || file.originalName }}
                    </span>
                    <a
                        *ngIf="accessService.hasAccess([permissions.File_Operations]) && allowFileAccess(file)"
                        class="anchor"
                        (click)="downloadFile(file._id, file.originalName)"
                    >
                        {{ file?.file?.name || file.originalName }}
                    </a>
                </ng-container>
                <span *ngIf="!file._id" class="anchor text-info" (click)="openFile(file)">
                    {{ file.name }}
                </span>
                <span
                    class="anchor text-size-small text-info ml-20"
                    *ngIf="
                        allowEdit &&
                        file._id &&
                        !file.certificate &&
                        (data.allowEdit || data.allowPartiallyEdit) &&
                        accessService.hasAccess([permissions.File_Sign])
                    "
                    (click)="signFile(file)"
                >
                    {{ localizations.common.sign }}
                </span>
                <span class="text-size-small text-grey-800 ml-20" *ngIf="file.signVerificationResult">
                    {{ getSignVerificationValue(file) }}
                </span>
                <span
                    class="heading-elements display-inline-block"
                    style="position: absolute"
                    *ngIf="file.signVerificationResult"
                >
                    <i [inlineSVG]="getAssetForSignVerificationValue(file)"></i>
                </span>
                <div class="display-inline-block ml-20" *ngIf="file.certificate">
                    <i
                        class="icon-medal text-danger cursor-pointer"
                        (click)="openCertificate(file.certificate)"
                        [title]="localizations.common.open_certificate"
                    >
                    </i>
                    <span class="text-size-small mr-10">
                        {{ getFileCertificateInfo(file) }}
                    </span>
                    <span class="anchor text-size-small text-info" (click)="verifySignFile(file)">
                        {{ localizations.common.check }}
                    </span>
                    <span
                        class="anchor text-size-small text-info ml-10"
                        *ngIf="file.signature"
                        (click)="downloadFileSignature(file)"
                    >
                        {{ localizations['appeals.documents'].download_signature }}
                    </span>
                    <span
                        class="anchor text-size-small text-info ml-10"
                        *ngIf="file.signature && isAllowCreatePdfWithSignature(file)"
                        (click)="createPdfWithSignatureFile(file)"
                    >
                        {{ localizations['appeals.documents'].download_pdf_with_signature }}
                    </span>
                </div>
                <span
                    class="anchor text-size-small text-info ml-10"
                    *ngIf="
                        allowEdit &&
                        file.certificate &&
                        (data.allowEdit || data.allowPartiallyEdit) &&
                        accessService.hasAccess([permissions.File_Sign])
                    "
                    (click)="signFile(file)"
                >
                    {{ localizations['appeals.documents'].resign }}
                </span>
            </td>
            <td
                class="text-right border-bottom border-bottom-default"
                [ngClass]="{
                    'no-border-top': fst,
                    'no-border-bottom': lst && !data.allowEdit && !data.allowPartiallyEdit
                }"
            >
                <ul class="icons-list">
                    <li *ngIf="document.requestId && data.envelope && file.envelopeDocGroupGuid">
                        <button
                            class="btn btn-default btn-xs btn-sxs"
                            [title]="localizations['appeals.documents'].form_pdf"
                            type="button"
                            (click)="generateResultPdfForResultDoc(file.envelopeDocGroupGuid)"
                        >
                            <i class="icon-file-pdf"></i>
                        </button>
                    </li>
                    <li>
                        <button
                            class="btn btn-default btn-xs btn-sxs"
                            type="button"
                            [disabled]="!allowEdit"
                            [title]="localizations['appeals.documents'].detach_file"
                            *ngIf="data.allowEdit || data.allowPartiallyEdit || data.allowDelete"
                            (click)="removeFile(file)"
                        >
                            <i class="icon-trash"></i>
                        </button>
                    </li>
                </ul>
            </td>
        </tr>
        <tr
            *ngIf="
                document.requestId &&
                ((document.files && document.files.length == 0) || documentService.calculateFileCount(data) == 0)
            "
        >
            <td class="info no-border-top" colspan="2">
                {{ localizations['appeals.documents'].files_missing }}
            </td>
        </tr>
    </tbody>
</table>

<div
    class="m-15"
    *ngIf="
        !task &&
        !document.requestId &&
        (data.allowEdit || data.allowPartiallyEdit) &&
        (accessService.hasAccess([permissions.File_Operations]) ||
            accessService.hasAccess([permissions.Documents_Scan])) &&
        !isDisabledByRso
    "
>
    <input
        id="file-{{ document.guid }}"
        class="inputfile hide"
        type="file"
        name="file-{{ document.guid }}"
        (change)="fileChange($event)"
    />
    <label
        class="btn btn-primary-overline btn-labeled btn-labeled-right btn-lg"
        for="file-{{ document.guid }}"
        *ngIf="accessService.hasAccess([permissions.File_Operations])"
    >
        {{ localizations.common.attach }}
        <ng-container *ngIf="group.scanFormat"> *.{{ group.scanFormat }} </ng-container>
        <b>
            <i inlineSVG="assets/icon-attach.svg"></i>
        </b>
    </label>

    <button
        class="btn btn-primary-overline btn-labeled btn-labeled-right btn-lg ml-15"
        type="button"
        [disabled]="isProcessingScan"
        *ngIf="accessService.hasAccess([permissions.Documents_Scan])"
        (click)="scanFiles()"
    >
        {{ localizations.common.scan }}
        <b>
            <i [ngClass]="{ flash: isProcessingScan }" width="24" height="24" inlineSVG="assets/icon-scan.svg"> </i>
        </b>
    </button>
    <div class="text-size-small text-muted mt-10" *ngIf="uploadedFileMaxSize > 0">
        <span class="text-semibold">
            {{ localizations.common.attention }}
        </span>
        {{ getMustNotExceedMessage() }}
    </div>
</div>

<fieldset *ngIf="data.envelope && data.envelope.messages">
    <legend class="text-bold pl-20 no-margin">
        {{ localizations.common.messages }}
    </legend>
    <table class="table table-xxs">
        <tbody>
            <tr *ngFor="let message of data.envelope.messages; let fst = first">
                <td [ngClass]="{ 'no-border-top': fst }">
                    {{ message.received | date }}
                    <ng-container *ngIf="message.author"></ng-container>
                </td>
                <td [ngClass]="{ 'no-border-top': fst }">
                    {{ message.message }}
                </td>
            </tr>
        </tbody>
    </table>
</fieldset>

<!-- Диалог для подписания файлов -->
<evolenta-cades-sign-modal
    (onSignCompletedEnvelope)="onSignatureComplete($event)"
    (onVerifyCadesBesSignCompleted)="onVerifyCadesBesSignComplete($event)"
></evolenta-cades-sign-modal>
