import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestService, TranslateService } from '@evolenta/core';
import { ASCENDING_ORDER, DEFAULT_SORT_FIELD } from '../../constants';

@Component({
    selector: 'sorting-bar',
    templateUrl: 'sorting-bar.component.html',
})
export class SortingBarComponent implements OnInit {
    public localizations;
    public sortItems;
    @Input() public selectedOrder = ASCENDING_ORDER;
    @Input() public collection;
    @Input() public disabledFilters;
    @Input() public defaultSortField = DEFAULT_SORT_FIELD;
    @Input() public selectedField;
    @Input() public selectedSortOrder;

    @Output() public fieldChange = new EventEmitter<any>();

    public constructor(private translate: TranslateService, private restService: RestService) {}

    public ngOnInit() {
        this._loadTranslations();
        if (this.selectedSortOrder) {
            this.selectedOrder = this.selectedSortOrder;
        }
        if (!this.selectedField) {
            this.selectedField = this.defaultSortField;
        }
    }

    public filterIsDisabled(item) {
        if (this.disabledFilters && this.disabledFilters.length && this.disabledFilters.includes(this.selectedField)) {
            // переключить фильтр, если он входит в заблокированные
            if (this.disabledFilters.includes(this.sortItems[0].field)) {
                this.selectedField = this.defaultSortField;
            } else {
                this.selectedField = this.sortItems[0].field;
            }
        }

        return this.disabledFilters && this.disabledFilters.includes(item.field);
    }

    private _loadTranslations() {
        this.translate.get(['common', 'sorting-bar']).subscribe(async (res: string) => {
            this.localizations = res;
            await this._fetchSortingItems();
        });
    }

    private async _fetchSortingItems() {
        const query = {
            field: 'entities',
            operator: 'in',
            value: [this.collection],
        };
        this.sortItems = await this.restService.search('sortingItems', { search: { search: [query] } });
    }

    public sortBy(field, order) {
        this.selectedField = field.field ? field.field : field;
        this.selectedOrder = order;
        this.fieldChange.emit({ field: this.selectedField, order: this.selectedOrder });
    }
}
