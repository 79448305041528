import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { NumberUtilities, UIUtilities } from '@evolenta/utilities';
import {
    CORE_CONFIG,
    CoreDynamicConfigInterface,
    FiltersService,
    StorageService,
    TranslateService,
} from '@evolenta/core';
import { Subject } from 'rxjs/Subject';
import { debounceTime } from 'rxjs/operators';
import { DatepickerComponent } from '@evolenta/forms';
import cloneDeep from 'lodash-es/cloneDeep';
import get from 'lodash-es/get';
import * as moment_ from 'moment';
// import {mobilePhoneMask} from "../../infrastructure/constants";

const moment = moment_;
const TREE_FILTER_IDENTIFIER = 'tree';

export const mobilePhoneMask = [
    '+',
    '7',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
];

@Component({
    selector: 'new-filters-bar',
    templateUrl: 'filters-bar.component.html',
    styleUrls: ['filters-bar.component.scss'],
})
export class NewFiltersBarComponent implements OnInit, AfterViewInit {
    @Input() public filters;
    @Input() public afterHeader = false;
    @Input() public filterName;
    @Input() public treeFilterEnabled;
    @Input() public base = []; // Дефолтные настройки поиска (чаще всего текущий МФЦ)
    @Input() public moduleBaseUrl;
    @Input() public scrollBarTopOffset = null;
    @Input() public hasTreeFilter;
    @Input() public arrSavedFilters = [];
    @Input() public treeLevelsToClear;

    @Output() public onApplyFilters = new EventEmitter<boolean>();
    @Output() public insideFiltersEmitter = new EventEmitter<any>();

    @Output() public onSaveUserFilter = new EventEmitter<any>();
    @Output() public onDeleteUserFilter = new EventEmitter<any>();
    @Output() public afterLoadFile = new EventEmitter<any>();
    @Output() public onTreeFilterEnabledChange = new EventEmitter<boolean>();

    @ViewChildren('datepicker') public datepickerComponents: QueryList<DatepickerComponent>;

    public insideFilters = [];
    public dateFormat = 'YYYY-MM-DDTHH:mm:ssZZ';
    public utcOffset = this.storage.getItem('utcOffset');
    public currentDate = moment().startOf('day').utcOffset(this.utcOffset);
    public currentDatePlusToDays = moment().add(2, 'd').endOf('day').utcOffset(this.utcOffset);

    public inputChanged: Subject<string> = new Subject<string>();

    public baseChanged = []; // Измененный набор базовых параметров поиска
    public isStart = true;
    public isEditFilter = false;
    public currentUserInsideFiltersArr = [];
    public localizations;
    public treeFilter;
    public masks = {
        mobilePhoneMask,
    };

    public constructor(
        @Inject(CORE_CONFIG) private readonly config: CoreDynamicConfigInterface,
        private storage: StorageService,
        private filtersService: FiltersService,
        private translate: TranslateService,
    ) {}

    public ngOnInit() {
        this._loadTranslations();
        // Параметр дополнения нулями при поиске
        if (this.hasTreeFilter) {
            this.treeFilter = this._getTreeFilter();
        }
        this.initStorageItems();
        this.baseChanged = cloneDeep(this.base);
        this.inputChanged.pipe(debounceTime(800)).subscribe(() => {
            this.beforeApplyFilters();
        });
    }

    public onTFEnabledChange(event) {
        this.treeFilterEnabled = event;
        this.onTreeFilterEnabledChange.emit(event);
    }

    public _getTreeFilter() {
        if (!this.filters || !this.filters.length || !this.filters[0].data) {
            return null;
        }

        const treeFilterContainer = this.filters[0].data.find(block =>
            block.filters.find(filter => filter.type === TREE_FILTER_IDENTIFIER),
        );
        if (treeFilterContainer) {
            return treeFilterContainer.filters.find(filter => filter.type === TREE_FILTER_IDENTIFIER);
        }

        return null;
    }

    private _loadTranslations() {
        this.translate.get(['common', 'appeals', 'services', 'organizations']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            if (this.filterName) {
                if (this.filtersService.savedFilters[this.filterName]) {
                    this.insideFilters = cloneDeep(this.filtersService.savedFilters[this.filterName]);
                    this.beforeApplyFilters();
                }
            }
            if (this.insideFilters.length === 0) {
                if (this._checkGroupsFilters(this.filters)) {
                    this.insideFilters = cloneDeep(this.filters);
                } else {
                    const structureGroup = {
                        group: '',
                        icon: '',
                        show: true,
                        data: this.filters,
                    };

                    this.insideFilters.push(structureGroup);
                }

                this._setFiltersDefaultValue();
            }

            if (this.isStart && this.moduleBaseUrl === 'inspector') {
                this.prepareFilters();
            }

            this._syncFilters();
        }, 0);
    }

    public get complaintsBasePath(): string {
        return this.config.paths.complaintsBase;
    }

    public get scrollbarHeight() {
        if (this.afterHeader) {
            return this.scrollBarTopOffset ? `calc(100% - ${this.scrollBarTopOffset}px)` : 'calc(100% - 69px)';
        } else {
            return '100%';
        }
    }

    public initStorageItems() {
        if (this.filters) {
            this.filters.forEach(filterNode => {
                if (filterNode.data) {
                    filterNode.data.forEach(child => {
                        this.initStorageItem(child);
                    });
                } else {
                    this.initStorageItem(filterNode);
                }
            });
        }
    }

    public initStorageItem(item) {
        if (item.filters) {
            item.filters.forEach(filter => {
                if (filter.storageItem && !filter.storageField) {
                    filter.items = this.storage.getItem(filter.storageItem);
                }
            });
        }
    }

    public getFilterLabel(filterLabel) {
        if (filterLabel.length > 7) {
            return filterLabel.substring(0, 7);
        }
        return filterLabel;
    }

    /**
     * Сворачивание / разворачивание элемента
     */
    public isCollapsed(nameClass: any, event: any): void {
        UIUtilities.IsCollapsed(nameClass, event, []);
    }

    private _generateSearchItemsByFields(filter, value): any[] {
        const search = [];
        let operatorType;
        if (filter.operator) {
            operatorType = filter.operator;
        } else {
            if (filter.items && filter.value && filter.value.operator) {
                operatorType = filter.value.operator;
            } else {
                operatorType = filter.isNumber || filter.isBoolean ? 'eq' : 'likeAnd';
            }
        }
        if (filter.format) {
            if (filter.format.byZero) {
                operatorType = 'eq';
                value = NumberUtilities.AddLeadingZerosToNumber(value, filter.format.length);
            }
        }
        if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }
        if (filter.fields.length > 1) {
            const orItems = [];
            // Обработка значений по принципу "ИЛИ"
            filter.fields.forEach(field => {
                if (!orItems.find(item => item.field === field)) {
                    orItems.push({
                        field,
                        operator: operatorType,
                        value: filter.isNumber ? parseFloat(value) : value,
                    });
                }
            });
            search.push({
                orSubConditions: orItems,
            });
        } else if (filter.fields.length) {
            if (filter.mask === '@mobilePhoneMask') {
                const orItems = [];
                orItems.push({
                    field: filter.fields[0],
                    operator: 'like',
                    value: value.replaceAll('_', '').trim(),
                });

                orItems.push({
                    field: filter.fields[0],
                    operator: 'like',
                    value: value.replaceAll('_', '').replaceAll(' ', ''),
                });

                search.push({ orSubConditions: orItems });
            } else {
                search.push({
                    field: filter.fields[0],
                    operator: operatorType,
                    value: filter.isNumber ? parseFloat(value) : value,
                });
            }
        }
        return search;
    }

    /**
     * Формирование поисковых элементов обработки INPUT полей
     * @param filter - обрабатываемый фильтр
     */
    private _prepareInputFilterValue(filter): any[] {
        let search = [];
        let orItems = [];
        // В настройках фильтра есть группировочные поля (разделяемые по пробелу), и в введенном значении есть пробел, например, ФИО, Серия + Номер документа
        if (filter.fieldsBySpace && filter.value.trim().indexOf(' ') !== -1) {
            // Разбиваем строку на подстроки
            const searchArr = filter.value.trim().split(' ');
            const andItems = [];
            searchArr.forEach((part, idx) => {
                andItems.push({
                    field: filter.fieldsBySpace[idx],
                    operator: 'like',
                    value: part,
                });
            });
            if (filter.fields.length > 0) {
                orItems = [];
                filter.fields.forEach(field => {
                    orItems.push({
                        field,
                        operator: 'like',
                        value: filter.value.trim(),
                    });
                });
                orItems.push({ andSubConditions: andItems });
            }
            search.push({ orSubConditions: orItems });
        } else {
            if (filter.fieldsBySpace) {
                filter.fields = filter.fields.concat(filter.fieldsBySpace);
            }
            search = search.concat(this._generateSearchItemsByFields(filter, filter.value.trim()));
        }
        return search;
    }

    private _prepareSelectFilterValue(filter) {
        return filter.value;
    }

    /**
     * Формирование поисковых элементов обработки чекбоксов - CHECKBOXES
     * @param filter - обрабатываемый фильтр
     */
    private _prepareCheckboxesFilterValue(filter): any[] {
        let checkboxSearch = [];

        if (filter.value.length > 0) {
            if (filter.subType === 'dates') {
                checkboxSearch = this._prepareDatesCheckboxFilterValue(filter);
            } else {
                checkboxSearch = this._prepareSimpleCheckboxFilterValue(filter);
            }
        } else if (Array.isArray(filter.parent) && filter.parentValue) {
            checkboxSearch = this._prepareCheckboxWithParentFilterValue(filter);
        }

        return checkboxSearch;
    }

    private _prepareCheckboxWithParentFilterValue(filter: any): any[] {
        const search = [];

        const itemsCodes = filter.items.map(item => item.code);
        const parentsCodes = filter.parent.map(item => item.code);
        const itemsCodesLikeParent = parentsCodes.filter(code => itemsCodes.includes(code));

        if (!itemsCodesLikeParent.includes(filter.parentValue.code)) {
            let andItems = [];

            filter.fields.forEach(field => {
                andItems = andItems.concat(
                    itemsCodesLikeParent.map(code => ({
                        field,
                        operator: 'neq',
                        value: code,
                    })),
                );
            });

            if (andItems.length > 0) {
                search.push({ andSubConditions: andItems });
            }
        }

        return search;
    }

    /**
     * Обработка чекбоксов - дат
     */
    private _prepareDatesCheckboxFilterValue(filter): any[] {
        const orItems = [];

        const dates = {
            current: this.currentDate.format(),
            currentMinusMonth: moment(this.currentDate).subtract(1, 'M').format(),
            currentPlusMonth: moment(this.currentDate).add(1, 'M').format(),
            null: null,
        };

        filter.value.forEach(item => {
            if (!filter.fields || !filter.fields.length) {
                return;
            }
            if (item.dates.end) {
                const andItems = [];
                andItems.push({
                    field: filter.fields[0],
                    operator: item.dates.start.operator,
                    value: dates[item.dates.start.value],
                });
                andItems.push({
                    field: filter.fields[0],
                    operator: item.dates.end.operator,
                    value: dates[item.dates.end.value],
                });
                orItems.push({ andSubConditions: andItems });
            } else {
                orItems.push({
                    field: filter.fields[0],
                    operator: item.dates.start.operator,
                    value: dates[item.dates.start.value],
                });
            }
        });

        return [{ orSubConditions: orItems }];
    }

    /**
     * Обработка простых чекбоксов
     */
    private _prepareSimpleCheckboxFilterValue(filter) {
        const orSubConditions = [];

        filter.value.forEach(item => {
            filter.fields.forEach(field => {
                let value;

                if (filter.itemValue) {
                    value = item[filter.itemValue];
                } else if (filter.operator === 'in') {
                    if (Array.isArray(item.code)) {
                        value = item.code;
                    } else {
                        value = [item.code];
                    }
                } else {
                    value = item.code;
                }

                let defaultOperator = 'eq';

                if (item.isInverse) {
                    defaultOperator = 'neq';
                }

                orSubConditions.push({
                    field,
                    operator: filter.operator || defaultOperator,
                    value,
                });

                if (item.isCancel && item.code === 'recall' && field === 'status') {
                    orSubConditions.push({
                        field: 'cancel',
                        operator: 'neq',
                        value: null,
                    });
                }
            });
        });

        return [{ orSubConditions }];
    }

    /**
     * Формирование поисковых элементов обработки выбора периода
     * @param filter - обрабатываемый фильтр
     */
    private _preparePeriodFilterValue(filter): any[] {
        const search = [];
        let field;

        if (filter.dates && filter.dates.length) {
            if (!filter.periodType) {
                filter.periodType = filter.dates[0];
            }
            field = filter.periodType.id;
        } else if (filter.field) {
            field = filter.field;
        }

        // ---- Обработка временных периодов ---- //
        let startDate;
        let endDate;

        if (filter.value.code === 'week') {
            startDate = moment().subtract(1, 'week').startOf('day').format(this.dateFormat);
            endDate = moment().endOf('day').format(this.dateFormat);
        } else if (filter.value.code === 'month') {
            startDate = moment().subtract(1, 'month').startOf('day').format(this.dateFormat);
            endDate = moment().endOf('day').format(this.dateFormat);
        } else if (filter.value.code === 'today') {
            startDate = moment().startOf('day').format(this.dateFormat);
            endDate = moment().endOf('day').format(this.dateFormat);
        } else if (filter.value.code === 'interval') {
            // [valueAsString]="false"
            if (get(filter, 'intervalStart.date')) {
                const intervalStartDate = filter.intervalStart.date;

                startDate = moment([intervalStartDate.year, intervalStartDate.month - 1, intervalStartDate.day]).format(
                    this.dateFormat,
                );
            }
            // [valueAsString]="true"
            if (filter.intervalStart && typeof filter.intervalStart === 'string') {
                const intervalStartDate = filter.intervalStart.split('-');

                startDate = moment([
                    intervalStartDate[0],
                    intervalStartDate[1] - 1,
                    intervalStartDate[2].split('T')[0],
                ]).format(this.dateFormat);
            }
            // [valueAsString]="false"
            if (get(filter, 'intervalEnd.date')) {
                const intervalEndDate = filter.intervalEnd.date;

                endDate = moment([intervalEndDate.year, intervalEndDate.month - 1, intervalEndDate.day])
                    .endOf('day')
                    .format(this.dateFormat);
            }
            // [valueAsString]="true"
            if (filter.intervalEnd && typeof filter.intervalEnd === 'string') {
                const intervalEndDate = filter.intervalEnd.split('-');

                endDate = moment([intervalEndDate[0], intervalEndDate[1] - 1, intervalEndDate[2].split('T')[0]])
                    .endOf('day')
                    .format(this.dateFormat);
            }
        } else if (filter.value.code === 'clearFilterItem') {
            filter.value.code = 'interval';
            filter.intervalStart = { date: null };
            filter.intervalEnd = { date: null };
        }

        if (startDate) {
            search.unshift({ field, operator: 'ge', value: startDate });
        }

        if (endDate) {
            search.push({ field, operator: 'le', value: endDate });
        }

        return search;
    }

    public changeAppealGroupFilter(group, filter) {
        filter.group = group;

        filter.insideItems.forEach(item => {
            item.isChecked = false;
        });
    }

    public changeCheckboxValue(filter) {
        filter.value = !filter.value;
        filter.label = filter.value ? (filter.shortName ? filter.shortName : filter.placeholder) : '';
        if (filter.actionType && filter.actionType === 'noLimitByUnit') {
            this.baseChanged = cloneDeep(this.base);
        }
        this.beforeApplyFilters();
    }

    private _prepareAppealsFilter(filter) {
        const search = [];
        const user = this.storage.getItem(this.config.storageConfig.activeUserKey);

        filter.value.forEach(value => {
            if (value.code === 'expired') {
                // статус в процессе и осталось 2 дня до datePlaneFinish

                search.push({
                    andSubConditions: [
                        {
                            field: 'datePlaneFinish',
                            operator: 'le',
                            value: moment().add(2, 'days').format(this.dateFormat),
                        },
                        {
                            field: 'datePlaneFinish',
                            operator: 'ge',
                            value: this.currentDate.format(this.dateFormat),
                        },
                        {
                            field: 'status.code',
                            operator: 'eq',
                            value: 'process',
                        },
                    ],
                });
            }

            if (value.code === 'overdue') {
                // статус в процессе и дата больше datePlaneFinish
                search.push({
                    andSubConditions: [
                        {
                            field: 'datePlaneFinish',
                            operator: 'le',
                            value: this.currentDate.format(this.dateFormat),
                        },
                        {
                            field: 'status.code',
                            operator: 'eq',
                            value: 'process',
                        },
                    ],
                });
            }

            if (value.code === 'control') {
                search.push({
                    field: 'controlOperator.id',
                    operator: filter.group.code === 'my' ? 'eq' : 'neq',
                    value: filter.group.code === 'my' ? user.id : null,
                });
            }
        });

        if (filter.group.code === 'my') {
            search.push({
                field: 'userLastModification.login',
                operator: 'eq',
                value: user.login,
            });
        }

        return search;
    }

    public getSelectsFromFilters() {
        let search = [];
        if (this.insideFilters) {
            this.insideFilters.forEach(group => {
                group.data.forEach(item => {
                    item.filters.forEach(filter => {
                        if (filter.type === 'select') {
                            search = search.concat(this._prepareSelectFilterValue(filter));
                        }
                    });
                });
            });
        }

        return search;
    }

    private _prepareUserFilters(isClear) {
        if (!isClear) {
            this.currentUserInsideFiltersArr = [];
            this.isActiveFilters();
            this.checkTypeValueDate();

            if (this.currentUserInsideFiltersArr && this.currentUserInsideFiltersArr.length > 0) {
                if (
                    !this.currentUserInsideFiltersArr[0].data.find(
                        item => item.name === this.localizations['filter-bar-settings'].saved_filters,
                    )
                ) {
                    this.currentUserInsideFiltersArr[0].data.unshift(this.insideFilters[0].data[0]);
                }
                this.insideFilters = this.currentUserInsideFiltersArr; // !!!! не трогать
            }
        } else {
            this.clearFilters();
            setTimeout(() => {
                if (this.arrSavedFilters.length === 0 && this.insideFilters[0].data[0].name === 'Сохраненные фильтры') {
                    this.insideFilters[0].data.shift();
                }
            }, 0);
        }
    }

    /**
     * Формирование массива отмеченных фильтров для процедуры поиска (загрузки данных)
     */
    public prepareFilters(isClear = false): any[] {
        if (this.moduleBaseUrl === 'complaints') {
            this._prepareUserFilters(isClear);
        }

        let search = [];
        this.filtersService.filterResult = {};

        if (this.insideFilters) {
            this.insideFilters.forEach(group => {
                if (!group.data) {
                    return;
                }
                group.data.forEach(item => {
                    if (!item.filters) {
                        return;
                    }

                    item.filters.forEach(filter => {
                        if (filter.value) {
                            // TODO сделано для переключения коллекций с учетом выбора в фильтре + FiltersBarComponent нужно полностью переделать
                            switch (filter.type) {
                                case 'checkboxes':
                                    this.filtersService.filterResult[item.code || item.name] = filter.value;
                                    break;
                                default:
                                    break;
                            }

                            if (filter.type === 'input') {
                                search = search.concat(this._prepareInputFilterValue(filter));
                            } else if (filter.type === 'checkboxes' && !filter.isInParentComponentProcessing) {
                                if (filter.processingFunction) {
                                    search.push(this[filter.processingFunction](filter));
                                } else {
                                    search = search.concat(this._prepareCheckboxesFilterValue(filter));
                                }
                            } else if (filter.type === 'period') {
                                search = search.concat(this._preparePeriodFilterValue(filter));
                            } else if (filter.type === 'radioboxes' && !filter.isInParentComponentProcessing) {
                                // Фильтр для связи актуальности дел (активные или завершенные) с доступными статусами (subservices.status.code)
                                switch (filter.value.code) {
                                    case 'appeals:actual': {
                                        search.push({
                                            orSubConditions: [
                                                {
                                                    field: 'status.code',
                                                    operator: 'eq',
                                                    value: 'draft',
                                                },
                                                {
                                                    field: 'status.code',
                                                    operator: 'eq',
                                                    value: 'process',
                                                },
                                            ],
                                        });
                                        break;
                                    }
                                    case 'appeals:complete': {
                                        search.push({
                                            orSubConditions: [
                                                {
                                                    field: 'status.code',
                                                    operator: 'eq',
                                                    value: 'complete',
                                                },
                                                {
                                                    field: 'subservices.status.code',
                                                    operator: 'eq',
                                                    value: 'serviceReject',
                                                },
                                                {
                                                    field: 'subservices.status.code',
                                                    operator: 'eq',
                                                    value: 'receiveDocsReject',
                                                },
                                                {
                                                    field: 'subservices.status.code',
                                                    operator: 'eq',
                                                    value: 'annulled',
                                                },
                                            ],
                                        });
                                        break;
                                    }
                                    default:
                                        search = search.concat(
                                            this._generateSearchItemsByFields(
                                                filter,
                                                filter.itemField ? filter.value[filter.itemField] : filter.value.code,
                                            ),
                                        );
                                }
                            } else if (filter.type === 'date') {
                                search.push({
                                    field: filter.fields[0],
                                    operator: 'eq',
                                    value:
                                        !filter.valueAsString && filter.value.date ? filter.value.date : filter.value,
                                });
                            } else if (filter.type === 'appeals') {
                                const appealFilters = this._prepareAppealsFilter(filter);
                                if (appealFilters.length !== 0) {
                                    search.push({ orSubConditions: this._prepareAppealsFilter(filter) });
                                }
                            } else if (
                                filter.type === 'checkbox' &&
                                filter.value &&
                                !filter.isInParentComponentProcessing
                            ) {
                                if (filter.actionType) {
                                    if (filter.actionType === 'noLimitByUnit' && this.base && this.base.length > 0) {
                                        const baseItems = [];
                                        this.base.forEach(baseItem => {
                                            if (filter.fields.indexOf(baseItem.field) === -1) {
                                                baseItems.push(baseItem);
                                            }
                                        });
                                        this.baseChanged = baseItems;
                                    }
                                } else if (filter.searchParams) {
                                    search.push(filter.searchParams);
                                } else {
                                    let checkboxValue = true;

                                    if (filter.storageItem) {
                                        const storageItemData = this.storage.getItem(filter.storageItem);

                                        if (filter.storageField) {
                                            checkboxValue = storageItemData[filter.storageField];
                                        }
                                    }

                                    if (filter.fields.length === 1) {
                                        search.push({
                                            field: filter.fields[0],
                                            operator: 'eq',
                                            value: filter.hasOwnProperty('useValue') ? filter.useValue : checkboxValue,
                                        });
                                    } else {
                                        const checkboxOrElements = [];
                                        filter.fields.forEach(field => {
                                            checkboxOrElements.push({
                                                field,
                                                operator: 'eq',
                                                value: filter.hasOwnProperty('useValue')
                                                    ? filter.useValue
                                                    : checkboxValue,
                                            });
                                        });
                                        search.push({
                                            orSubConditions: checkboxOrElements,
                                        });
                                    }
                                }
                            } else if (filter.type === 'dictionary') {
                                if (filter.fields && filter.fields.length === 1 && filter.value.name !== null) {
                                    search.push({
                                        field: filter.fields[0],
                                        operator: 'eq',
                                        value: filter.value[filter.dictionary.field],
                                    });
                                } else {
                                    const fieldsOrElements = [];
                                    if (filter.fields && filter.fields.length) {
                                        filter.fields.forEach(field => {
                                            if (filter.value.name !== null) {
                                                fieldsOrElements.push({
                                                    field,
                                                    operator: 'eq',
                                                    value: filter.value[filter.dictionary.field],
                                                });
                                            }
                                        });
                                    }

                                    if (fieldsOrElements.length) {
                                        search.push({
                                            orSubConditions: fieldsOrElements,
                                        });
                                    }
                                }
                            } else if (filter.type === 'multipleListWithManualInput') {
                                const fieldsOrElements = [];
                                filter.fields.forEach(field => {
                                    if (filter.value && filter.value.length > 0) {
                                        filter.value.forEach(element => {
                                            fieldsOrElements.push({
                                                field,
                                                operator: 'like',
                                                value: element.value,
                                            });
                                        });
                                    }
                                });
                                if (fieldsOrElements.length) {
                                    search.push({
                                        orSubConditions: fieldsOrElements,
                                    });
                                }
                            } else if (filter.type === 'select') {
                                const fieldsOrElements = [];
                                filter.fields.forEach(field => {
                                    fieldsOrElements.push({
                                        field,
                                        operator: 'eq',
                                        value: filter.value.id,
                                    });
                                });
                                if (fieldsOrElements.length) {
                                    search.push({
                                        orSubConditions: fieldsOrElements,
                                    });
                                }
                            }
                        } else if (
                            !filter.value &&
                            filter.type === 'checkbox' &&
                            filter.storageItem === 'user' &&
                            !filter.value &&
                            this.isStart &&
                            this.moduleBaseUrl === 'inspector' &&
                            !filter.isInParentComponentProcessing
                        ) {
                            // если инспектор (на старте) - то чекбокс включить и отфильтровать
                            let checkboxValue = true;
                            if (filter.storageItem) {
                                const storageItemData = this.storage.getItem(filter.storageItem);
                                if (filter.storageField) {
                                    checkboxValue = storageItemData[filter.storageField];
                                }
                            }
                            if (filter.fields.length === 2) {
                                const checkboxOrElements = [];
                                filter.fields.forEach(field => {
                                    checkboxOrElements.push({
                                        field,
                                        operator: 'eq',
                                        value: filter.hasOwnProperty('useValue') ? filter.useValue : checkboxValue,
                                    });
                                });
                                search.push({ orSubConditions: checkboxOrElements });
                                filter.value = true;
                                this.isStart = false;
                                this.onApplyFilters.emit(true);
                            }
                        }
                    });
                });
            });
        }
        search = search.concat(this.baseChanged);
        return search;
    }

    private _checkGroupsFilters(filters): boolean {
        return !!get(filters, '[0].groupId', null);
    }

    /**
     * Активный таб
     */
    public setActiveTab(groups: any[], num: any) {
        groups.forEach((group, index) => {
            group.show = num === index;
        });
    }

    /**
     * Обработка выбора / отмены выбора элементов из списка чекбоксов
     * @param filter - обрабатываемый фильтр
     * @param item - элемент (отдельный чекбокс)
     */
    public selectCheckboxItem(filter, item, isGroup = false) {
        if (!filter.value) {
            filter.value = [];
        }

        const bool =
            filter.value.filter(
                (element: { code: string; parentCode?: string }) =>
                    element.code === get(filter, 'parentValue.code') ||
                    element.parentCode === get(filter, 'parentValue.code'),
            ).length === 1;

        item.isChecked = !item.isChecked;

        // Вычисление значения фильтра
        if (item.isChecked) {
            filter.value.push(item);
        } else {
            filter.value.splice(filter.value.indexOf(item), 1);
        }
        if (item.isMain) {
            let hierarchy = [];
            hierarchy = filter.items.filter(
                filterItem =>
                    filterItem.code &&
                    filterItem.code.length &&
                    filterItem.code[0] === item.code &&
                    filterItem.code !== item.code,
            );
            hierarchy.forEach(itemForCheck => {
                itemForCheck.isChecked = !itemForCheck.isChecked;
                if (item.isChecked) {
                    filter.value.push(itemForCheck);
                } else {
                    filter.value.splice(filter.value.indexOf(itemForCheck), 1);
                }
            });
        }
        // Если элемент содержит ссылки на дочерние
        if (isGroup) {
            if (!filter.showHidden) {
                if (item.isChecked) {
                    filter.items.forEach(elm => {
                        if (item.listCode.indexOf(elm.code) !== -1) {
                            if (!elm.isChecked) {
                                elm.isChecked = true;
                                filter.value.push(elm);
                            }
                        }
                    });
                } else {
                    filter.items.forEach(elm => {
                        if (item.listCode.indexOf(elm.code) !== -1) {
                            if (elm.isChecked) {
                                elm.isChecked = false;
                                filter.value.splice(filter.value.indexOf(elm), 1);
                            }
                        }
                    });
                }
            }
        }

        // Корректировка информации по выбранным значениям (label) фильтра
        if (filter.value.length === 1) {
            filter.label = filter.itemShortName
                ? filter.value[0][filter.itemShortName]
                : filter.itemName
                ? filter.value[0][filter.itemName]
                : filter.value[0].name;
        } else if (filter.value.length > 1) {
            filter.label = 'выбрано: ' + filter.value.length;
        } else {
            delete filter.label;
        }

        // Сброс фильтра при деактивации чекбокса относящегося к текущему родителю по коду
        if (
            !item.isChecked &&
            filter.parentValue &&
            (get(filter, 'parentValue.code') === item.code ||
                (bool && get(filter, 'parentValue.code') === item.parentCode))
        ) {
            filter.parentValue = filter.parent[0];
            this._clearFilterItem(filter);
        }

        this.beforeApplyFilters();
    }

    public changeParentValue(filter) {
        filter.value = [];
        if (!filter.parentValue.showChild) {
            const values = filter.items.filter(item => {
                return (
                    item.code === filter.parentValue.code ||
                    item.parentCode === filter.parentValue.code ||
                    (filter.parentValue.codes && filter.parentValue.codes.indexOf(item.code) !== -1)
                );
            });
            filter.value = filter.value.concat(filter.value, values);
        }

        if (filter.type === 'checkboxes') {
            const bool =
                filter.value.filter(
                    (item: { code: string; parentCode?: string }) =>
                        item.code === filter.parentValue.code || item.parentCode === filter.parentValue.code,
                ).length === 1;

            // Активация чекбокса, код которого совпадает с кодом текущего родителя
            if (bool) {
                const findItem = filter.items.find(
                    (item: { code: string; parentCode?: string }) =>
                        item.code === filter.parentValue.code || item.parentCode === filter.parentValue.code,
                );

                if (findItem) {
                    findItem.isChecked = true;
                }
            } else {
                this._clearFilterItem(filter);
            }
        }

        this.beforeApplyFilters();
    }

    private _changeRadioboxesValueToDefault(filter) {
        const findItem = filter.items.find(item => item.isDefault);
        if (findItem) {
            findItem.isChecked = true;
        } else {
            this._clearFilterItem(filter);
        }
        this.beforeApplyFilters();
    }

    public selectPeriodType(date, filter) {
        filter.periodType = date;
        this.beforeApplyFilters();
    }

    public selectInputType(type: { id: string; text: string }, filter: any): void {
        filter.selectedType = filter.types.find((item: { id: string }) => item.id === type.id);
        this.beforeApplyFilters();
    }

    public getActiveInputType(filter: any): any[] {
        if (!filter.types || !filter.types.length) {
            return [];
        }

        if (filter.selectedType && filter.selectedType.id) {
            return filter.types.filter((item: any) => item.id === filter.selectedType.id).slice(0, 1);
        }

        return [filter.types[0]];
    }

    public getInputProperty(key: string, filter: any): any {
        return filter.selectedType ? filter.selectedType[key] : filter[key];
    }

    public selectSelectType(data, filter) {
        filter.value = data;
        this.beforeApplyFilters();
    }

    public selectDictionaryValue(value, filter) {
        filter.value = cloneDeep(value);
        filter.label = filter.value[filter.labelField].substr(0, 10);
        this.beforeApplyFilters();
    }
    public clearDictionaryValue(data, filter) {
        filter.value = null;
        filter.label = null;
        this.beforeApplyFilters();
    }

    public addMultipleListItem(filter: any): void {
        if (!filter.value) {
            filter.value = [];
        }
        filter.value.push({ value: null });
    }

    public deleteMultipleListItem(filter, idx): void {
        filter.value.splice(idx, 1);
        this.beforeApplyFilters();
    }

    /**
     * Расчет ограничений по срокам актуальности: время обработки, и срок хранения
     */
    public getDateLimits(filter): any {
        const resultItems = [];
        filter.value.forEach(valueItem => {
            if (valueItem.processingProperty === 'expired') {
                resultItems.push({
                    andSubConditions: [
                        {
                            field: 'subservices.status.code',
                            operator: valueItem.appealStatusCode ? 'eq' : 'in',
                            value: valueItem.appealStatusCode
                                ? valueItem.appealStatusCode
                                : valueItem.appealStatusCodes,
                        },
                        {
                            field: valueItem.field,
                            operator: 'le',
                            value: this.currentDate.format(this.dateFormat),
                        },
                    ],
                });
            } else {
                resultItems.push({
                    andSubConditions: [
                        {
                            field: 'subservices.status.code',
                            operator: valueItem.appealStatusCode ? 'eq' : 'in',
                            value: valueItem.appealStatusCode
                                ? valueItem.appealStatusCode
                                : valueItem.appealStatusCodes,
                        },
                        {
                            field: valueItem.field,
                            operator: 'gt',
                            value: this.currentDate.format(this.dateFormat),
                        },
                        {
                            field: valueItem.field,
                            operator: 'lt',
                            value: this.currentDatePlusToDays.format(this.dateFormat),
                        },
                    ],
                });
            }
        });
        if (resultItems.length === 1) {
            return resultItems[0];
        } else {
            const orSubConditions = [];
            resultItems.forEach(item => {
                orSubConditions.push(item);
            });
            return { orSubConditions };
        }
    }

    /**
     * Метод задания информации по введенному значению (label) для поля ввода Input
     * @param value - введенное значение
     * @param filter - обрабатываемый фильтр
     * @param group - обрабатываемая группа фильтров
     */
    public changeInputValue(value, filter, group) {
        if (value) {
            if (group.filters.length === 1 || !filter.shortName) {
                filter.label = value.length <= 10 ? value : value.substring(0, 10) + '...';
            } else {
                filter.label = filter.shortName;
            }
        } else {
            delete filter.label;
        }
        this.inputChanged.next(value);
    }

    public changeRadioValue(item, filter) {
        filter.value = item;

        if (filter.value) {
            if (filter.labelName) {
                filter.label = filter.value[filter.labelName];
            } else {
                filter.label = filter.value.shortName ? filter.value.shortName : filter.value.name;
            }

            if (filter.dependFilter) {
                this.processDependFilter(filter);
            }
        } else {
            delete filter.label;
            delete filter.intervalStart;
            delete filter.intervalEnd;
            delete filter.periodType;
        }

        this.beforeApplyFilters();
    }

    /**
     * Событие изменения даты в датапикере
     * @param filter - обрабатываемый фильтр
     * @param group - группа, в которой находится фильтр
     */
    public changeDateValue(filter, group) {
        if (filter.value === 'Invalid date') {
            filter.value = '';
        }
        if (filter.value) {
            filter.label = group.filters.length === 1 || !filter.shortName ? filter.value.formatted : filter.shortName;
        } else {
            delete filter.label;
        }
        this.beforeApplyFilters();
    }

    /**
     * Определение наличия выбранных / введенных значений в группе фильтров
     * @param filtersGroup - группа фильтров
     */
    public isGroupFilterHasValue(filtersGroup): boolean {
        let hasValue = false;
        filtersGroup.filters.forEach(filter => {
            if (filter.value) {
                // Если фильтр - массив элементов: типы - checkboxes
                if ((Array.isArray(filter.value) && filter.value.length > 0) || !Array.isArray(filter.value)) {
                    hasValue = true;
                }
            }
        });
        return hasValue;
    }

    /**
     * Проверка наличия активных фильтров для отображения кнопок "применить" и "очистить"
     */
    public isActiveFilters(): boolean {
        let isActive = false;
        if (this.insideFilters) {
            this.insideFilters.forEach(group => {
                if (group.data) {
                    group.data.forEach(categoryFilter => {
                        if (categoryFilter.filters) {
                            categoryFilter.filters.forEach(filter => {
                                if (filter.label) {
                                    isActive = true;
                                    if (
                                        filter.type === 'dictionary' &&
                                        filter.dictionary.name === 'userUnitsSavedFilters' &&
                                        filter.value
                                    ) {
                                        this.currentUserInsideFiltersArr = cloneDeep(filter.value.insideFilters);
                                    }
                                }
                                if (
                                    filter.onlyPeriod &&
                                    ((filter.intervalStart && filter.intervalStart.date) ||
                                        (filter.intervalEnd && filter.intervalEnd.date))
                                ) {
                                    isActive = true;
                                }
                            });
                        }
                    });
                }
            });
        }

        return isActive;
    }

    /**
     * Очистка отдельного фильтра в группе фильтров
     * @param filter - обрабатываемый фильтр
     */
    private _clearFilterItem(filter, type?) {
        let value = null;

        if (filter.type === 'appeals') {
            filter.insideItems.map(item => (item.isChecked = false));
        }

        if (filter.actionType && filter.actionType === 'noLimitByUnit') {
            this.baseChanged = cloneDeep(this.base);
        }

        if (filter.type === 'period' && filter.onlyPeriod) {
            value = { code: 'clearFilterItem' };
            this.beforeApplyFilters();
        }

        if (filter.type === 'multipleListWithManualInput') {
            value = [];
            this.beforeApplyFilters();
        }

        if (filter.type === 'checkboxes') {
            value = [];
            filter.items.map(item => (item.isChecked = false));
        }

        if (filter.type === 'dictionary') {
            value = { code: null, name: null, _id: null };
        }

        if (filter.type === 'date' && type === 'all') {
            this._clearDates();
        }

        filter.value = value;

        if (filter.dependFilter) {
            this.processDependFilter(filter);
        }

        delete filter.label;
        delete filter.group;

        if (type === 'one') {
            this.beforeApplyFilters();
        } else if (type === 'all') {
            if (!this.isActiveFilters()) {
                this.beforeApplyFilters();
            }
        }
    }

    /**
     * Очистка поля даты
     */
    private _clearDates(): void {
        if (this.datepickerComponents && this.datepickerComponents.length) {
            this.datepickerComponents.forEach((component: DatepickerComponent) => (component.formatDate = ''));
        }
    }

    /**
     * Очистка всех фильтров
     */
    public clearFilters(type?) {
        if (this.insideFilters) {
            this.insideFilters.forEach(group => {
                group.data.forEach(categoryFilter => {
                    categoryFilter.filters.forEach(filter => {
                        if (filter.label || filter.onlyPeriod) {
                            this._clearFilterItem(filter, type || 'all');
                        }
                        if (filter.parent) {
                            filter.parentValue = filter.parent[0];
                        }
                    });
                });
            });
        }
    }

    public saveUserFilter(filter) {
        if (!this.insideFilters || !this.insideFilters.length) {
            return;
        }

        const saveArr = cloneDeep(this.insideFilters);

        if (this.insideFilters[0].data.find(item => item.name === 'Сохраненные фильтры')) {
            saveArr[0].data.shift(); // при сохранение в БД удаляем фильтр сохранения
        }

        const saveObj = {
            value: filter ? filter.value : null,
            saveArr,
        };

        this.onSaveUserFilter.emit(saveObj);
    }

    public checkToggleParent(filter, elm) {
        const findParent = filter.items.find(item => item.parentCode === elm.parentCode);
        return findParent.showHidden;
    }

    /**
     * Переключение видимости скрытых элементов
     * @param toggleItem - скрывающийся / раскрывающийся элемент
     */
    public toggleHidden(filter, toggleItem) {
        toggleItem.showHidden = !toggleItem.showHidden;
        toggleItem.isShow = !toggleItem.isShow;

        // Отображение / скрытие дочерних элементов
        filter.items = filter.items.map(item => {
            if (item.parentCode === toggleItem.code) {
                item.isShow = toggleItem.showHidden;
            }
            return item;
        });

        const itemsWithList = filter.items.filter(elm => elm.listCode); // элементы с дочерними списками
        itemsWithList.forEach(elm => {
            if (!elm.isChecked) {
                filter.items.forEach(item => {
                    if (elm.listCode.indexOf(item.code) !== -1) {
                        if (item.isChecked) {
                            item.isChecked = false;
                            filter.value.splice(filter.value.indexOf(item), 1);
                        }
                    }
                });
            } else {
                filter.items.forEach(item => {
                    if (elm.listCode.indexOf(item.code) !== -1) {
                        if (!item.isChecked) {
                            item.isChecked = true;
                            filter.value.push(item);
                        }
                    }
                });
            }
        });
        this.beforeApplyFilters();
    }

    /**
     * Сохранение настроек фильтров
     */
    public beforeApplyFilters() {
        if (this.filterName) {
            if (this._checkFiltersValues()) {
                this.filtersService.savedFilters[this.filterName] = cloneDeep(this.insideFilters);
            } else {
                delete this.filtersService.savedFilters[this.filterName];
            }
        }
        this.insideFiltersEmitter.emit(this.insideFilters);
        this.onApplyFilters.emit(true);
    }

    /**
     * Проверка на наличие настроенных фильтров (выбранные значения)
     */
    private _checkFiltersValues(): boolean {
        let hasValue = false;

        if (this.insideFilters) {
            this.insideFilters.forEach(group => {
                group.data.forEach(filterItems => {
                    if (filterItems.filters) {
                        filterItems.filters.forEach(filter => {
                            if (filter.value || (group.groupId === 'add-group' && filter.group)) {
                                hasValue = true;
                            }
                        });
                    }
                });
            });
        }

        return hasValue;
    }

    /**
     * Проверка на наличия включенного параметра обработки элемента в родительском компоненте (для отработки униклаьного метода фильтрации)
     */
    public checkInComponentProcessingParam(filterCode = null) {
        let result = false;

        if (this.insideFilters && this.insideFilters.length) {
            this.insideFilters[0].data.forEach(filterItem => {
                const find = filterItem.filters.find(
                    item => (!filterCode || filterCode === item.code) && item.isInParentComponentProcessing,
                );

                result = result || (find && find.value);
            });
        }

        return result;
    }

    public deleteUserFilter(id) {
        this.onDeleteUserFilter.emit(id);
    }

    public restoreInsideFilters(filters) {
        if (!this.insideFilters || !this.insideFilters.length) {
            return;
        }

        const name = 'Сохраненные фильтры';

        if (
            (filters.find(item => item.name === name) &&
                !this.insideFilters[0].data.find(item => item.name === name)) ||
            (!filters.find(item => item.name === 'Сохраненные фильтры') &&
                this.insideFilters[0].data.find(item => item.name === name))
        ) {
            this.insideFilters[0].data = cloneDeep(filters);
        }
    }

    public checkTypeValueDate() {
        // для того чтобы фильтр фильтровал и ОТОБРАЖАЛ данные в сохраненных фильтрах жалоб - тип - 2021-06-01T00:00:00.000+0300
        // из-за [valueAsString]="true/false" у evolenta-datepicker
        const textEnd = 'T00:00:00.000+0300';

        this.currentUserInsideFiltersArr.forEach(item => {
            item.data.forEach(it => {
                if (it.name === 'Период') {
                    it.filters.forEach(arr => {
                        if (get(arr, 'intervalStart.formatted')) {
                            const dt = arr.intervalStart.formatted.split('.');

                            arr.intervalStart = dt[2] + '-' + dt[1] + '-' + dt[0] + textEnd;
                        }

                        if (get(arr, 'intervalEnd.formatted')) {
                            const dt2 = arr.intervalEnd.formatted.split('.');

                            arr.intervalEnd = dt2[2] + '-' + dt2[1] + '-' + dt2[0] + textEnd;
                        }
                    });
                }
            });
        });
    }

    public showCheckboxByParent(filter: any, item: any): boolean {
        if (!filter.parent) {
            return true;
        }

        let show = false;

        if (filter.parentValue) {
            const bool = filter.parent.some(
                (obj: { code: string }) => obj.code === item.code || obj.code === item.parentCode,
            );

            // Отображаение чекбокса, если его код совпадает с текущем родителем,
            // либо, если кода текущего родителя нету среди возможных чекбоксов
            if (Array.isArray(filter.parent) && bool) {
                show =
                    filter.parentValue.code &&
                    (filter.parentValue.code === item.code || filter.parentValue.code === item.parentCode);
            } else {
                show = Array.isArray(filter.items) && !filter.items.some(obj => obj.code === filter.parentValue.code);
            }
        }

        return show;
    }

    public showCheckboxByVisibility(filter: any, item: any, itemIdx: number): boolean {
        if (filter.hiddenProperty) {
            return !item.isGroup && (!item.parentCode || item.isShow);
        }
        if (!filter.countVisible) {
            return true;
        }
        return filter.showAll || itemIdx < filter.countVisible;
    }

    public buttonFileChange(event, filter) {
        const filesList = event.target.files;

        if (filesList.length > 0) {
            filesList[0].dateCreation = moment().format();

            const file = filesList[0];
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.afterLoadFile.emit({ action: filter.action, data: e.target.result });
            };

            reader.readAsText(file);
        }
    }

    public processDependFilter(parentFilter) {
        let dependedFilter;
        let dependGroup;

        if (this.insideFilters) {
            this.insideFilters.forEach(group => {
                group.data.forEach(groupFilters => {
                    groupFilters.filters.forEach(filter => {
                        if (filter.code && filter.code === parentFilter.dependFilter) {
                            dependedFilter = filter;
                            dependGroup = groupFilters;
                        }
                    });
                });
            });
        }

        if (dependedFilter) {
            const value = parentFilter.value;

            if (value && !value.showChild) {
                dependedFilter.value = null;
                dependGroup.isHidden = true;

                dependedFilter.items.forEach(item => {
                    item.isChecked = false;
                });
            } else {
                dependGroup.isHidden = false;

                dependedFilter.items.forEach(item => {
                    // item.isChecked = false;
                    item.isShow =
                        !value ||
                        !value.showDependChild ||
                        (value.showDependChild instanceof Array && value.showDependChild.includes(item.code));
                });
            }
        }
    }

    /**
     * Получить массив с настройками всех аггрегаций, необходимых для фильтров
     */
    public getAggregationParamsNames() {
        const result = [];

        if (this.insideFilters) {
            this.insideFilters.forEach(group => {
                group.data.forEach(item => {
                    if (!item.filters) {
                        return;
                    }

                    item.filters.forEach(filter => {
                        if (filter.value && filter.value.length) {
                            if (filter.aggregationParamsName && filter.aggregationParamsName.length) {
                                result.push(filter.aggregationParamsName);
                            }
                        }
                    });
                });
            });
        }

        return result;
    }

    /**
     * Синхронизация зависимостей для фильтров
     */
    private _syncFilters() {
        if (this.insideFilters) {
            this.insideFilters.forEach(groups => {
                if (groups.data) {
                    groups.data.forEach(categoryFilter => {
                        categoryFilter.filters.forEach(filter => {
                            if (filter.dependFilter) {
                                this.processDependFilter(filter);
                            }
                        });
                    });
                }
            });
        }
    }

    public isShowCheckBlock(filter, item, sub?) {
        if (sub === undefined) {
            return filter.hiddenProperty && item.isGroup;
        }

        return (
            (filter.hiddenProperty && !item.isGroup && (item.isShow || !item.hasOwnProperty('isShow'))) ||
            (!filter.hiddenProperty &&
                (!filter.countVisible ||
                    (filter.countVisible && (filter.showAll || (!filter.showAll && sub < filter.countVisible)))))
        );
    }

    private _setFiltersDefaultValue(): void {
        if (Array.isArray(this.insideFilters)) {
            if (this.insideFilters) {
                this.insideFilters.forEach(insideFilter => {
                    if (insideFilter && Array.isArray(insideFilter.data)) {
                        insideFilter.data.forEach(data => {
                            if (Array.isArray(data.filters)) {
                                data.filters.forEach(filter => {
                                    if (
                                        filter.type === 'checkboxes' ||
                                        (filter.type === 'radioboxes' && filter.setDefault)
                                    ) {
                                        this._setCheckboxesFilterDefaultValue(filter);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        }
    }

    private _setCheckboxesFilterDefaultValue(filter: any): void {
        if (filter.type === 'radioboxes' && filter.setDefault) {
            const defaultVal = filter.items.find(item => item.isDefault);
            if (defaultVal) {
                filter.value = defaultVal;
                this._changeRadioboxesValueToDefault(filter);
            }
        }
        if (Array.isArray(filter.parent) && !filter.parentValue) {
            const defaultValue = filter.parent.find(item => item.isDefault);
            if (defaultValue) {
                filter.parentValue = defaultValue;
                this.changeParentValue(filter);
            }
        }
    }

    public prepareDictionaryBaseSearch(filter) {
        let baseSearch = filter.dictionary.baseSearch ? filter.dictionary.baseSearch : null;
        if (filter.dictionary.dependFromParentFilter) {
            let parentFilter;
            if (this.insideFilters) {
                this.insideFilters.forEach(insideFilter => {
                    if (insideFilter && Array.isArray(insideFilter.data)) {
                        insideFilter.data.forEach(data => {
                            if (Array.isArray(data.filters)) {
                                data.filters.forEach(filterItem => {
                                    if (
                                        filterItem.code &&
                                        filterItem.code === filter.dictionary.dependFromParentFilter
                                    ) {
                                        parentFilter = filterItem;
                                    }
                                });
                            }
                        });
                    }
                });
            }
            if (parentFilter && parentFilter.value) {
                baseSearch = [
                    {
                        field: filter.dictionary.dictionaryField,
                        operator: 'eq',
                        value: parentFilter.value[filter.dictionary.parentDictionaryField],
                    },
                ];
            }
        } else if (filter.dictionary.dependFromStorageElement) {
            const storageElement = this.storage.getItem(filter.dictionary.dependFromStorageElement);
            if (!baseSearch) {
                baseSearch = [];
            }
            if (storageElement && filter.dictionary.storageElementField) {
                let fieldData = get(storageElement, filter.dictionary.storageElementField);
                if (fieldData) {
                    if (filter.dictionary.mapField) {
                        fieldData = fieldData.map(item => item[filter.dictionary.mapField]);
                    }
                    baseSearch.push({
                        field: filter.dictionary.dictionaryField,
                        operator: Array.isArray(fieldData) ? 'in' : 'eq',
                        value: fieldData,
                    });
                }
            }
        }
        return baseSearch;
    }

    public hideHierarchyChildren(filter: any, item: any): void {
        item.showHidden = !item.showHidden;
        // Отображение / скрытие дочерних элементов
        filter.items.map(filterItem => {
            if (
                filterItem.code &&
                filterItem.code.length &&
                filterItem.code[0] === item.code &&
                filterItem.code !== item.code
            ) {
                filterItem.isShown = !filterItem.isShown;
            }
        });
    }

    public getMask(name: string) {
        if (!name || typeof name !== 'string') {
            return name;
        }

        return this.masks[name.replace('@', '')] || name;
    }
}
