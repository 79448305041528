import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RestService, ToasterService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { DocumentService } from '../../document.service';
import { PrintFormsService } from '../../../../../../../common/services/print/print-forms.service';
import { EnvelopeDataComponent } from '../../../../../../envelopes/envelopes/components/envelope-data/envelope-data.component';
import * as _ from 'lodash-es';

@Component({
    selector: 'appeal-document-envelope',
    templateUrl: 'appeal-document-envelope.component.html',
})
export class AppealDocumentEnvelopeComponent implements OnInit {
    @Input() public document; // документ дела

    public isProcessSelectOrganization = false; // Флаг процесса выбора организации

    public data; // описательный объект для обрабатываемого документа
    @ViewChild('envelopeData', { static: false }) public envelopeDataComponent: EnvelopeDataComponent;
    @Output() public afterSendEnvelope = new EventEmitter<boolean>();
    @Output() public afterSaveEnvelope = new EventEmitter<boolean>(); // реакция на сохранение данных

    public constructor(
        private rest: RestService,
        private toaster: ToasterService,
        private documentService: DocumentService,
        private printFormsService: PrintFormsService,
    ) {}

    /**
     * Инициализация компонента
     */
    public async ngOnInit() {
        this.data = this.documentService.data[this.document.guid];
        if (!this.data.envelopeData) {
            this.data.envelopeData = {};
        }

        if (!this.data.envelope || !this.data.envelope.request) {
            await this.getRequest();
        }

        if (this.document.envelopeId) {
            await this.getEnvelope();
        }

        if (!this.data.envelope.appealId) {
            this.data.envelope.appealId = this.data.appealId;
        }
    }

    /**
     * Выбор организации
     * @param agency
     */
    public selectOrganization(agency) {
        this.data.envelope.agency = agency;
        this.isProcessSelectOrganization = false;
    }

    /**
     * Получение запроса
     */
    public async getRequest() {
        try {
            const response: any = await this.rest.find('requests', this.data.requestId);
            if (!this.data.envelope) {
                this.data.envelope = {
                    guid: CommonUtilities.GenerateGuid(),
                    subserviceId: this.data.subserviceId,
                    subserviceGuid: this.document.subserviceGuid,
                    documentGuid: this.document.guid,
                    object: null,
                    appealDocuments: [],
                };
            }
            if (this.document.isForwardingRequest) {
                response.isForwardingRequest = true;
            }
            this.data.envelope.request = response;
            if (this.data.envelope.request.agencies.length > 1) {
                // Инициализируем выбор организации
                this.isProcessSelectOrganization = true;
            } else {
                this.data.envelope.agency = this.data.envelope.request.agencies[0];
            }
        } catch (error) {
            this.toaster.html('Произошла ошибка при получении запроса ' + error);
        }
    }

    /**
     * Получение конверта
     */
    public async getEnvelope() {
        if (!this.data.envelope) {
            try {
                const response = await this.rest.find('envelopes', this.document.envelopeId);
                this.data.envelope = response;
                if (!this.data.envelope.appealDocuments) {
                    this.data.envelope.appealDocuments = [];
                }
            } catch (error) {
                this.toaster.html('Произошла ошибка при получении конверта для запроса: ' + error);
            }
        }
    }

    public saveEnvelope() {
        this.envelopeDataComponent.save(false, null, true);
    }

    /**
     * Получение результата сохранения
     * @param data - объект формата {envelope: объект, errors: ошибки}
     */
    public onAfterGetEnvelopeXsdData(data) {
        this.afterSaveEnvelope.emit(true);
    }

    /**
     * Отправка запроса средствами компонента EnvelopeDataComponent
     */
    public async processSendEnvelope(): Promise<void> {
        const appealData = await this.printFormsService.prepareAppealData(
            this.documentService.appeal,
            this.documentService.subservice,
            this.document,
        );
        await this.envelopeDataComponent.save(true, appealData);
    }

    /**
     * Получение результата отправки запроса
     * @param data - объект формата {envelope: измененный после отправки запрос-конверт, success: статус отправки}
     */
    public onAfterSendEnvelope(data) {
        this.data.envelope = data.envelope;
        this.data.baseEnvelope = data.envelope; // Замена сравнительного объекта для предотвращения ложных сохранений
        this.documentService.calculateDocumentPermissions(this.document.guid);
        if (data.envelope.status === 'sended' || data.envelope.status === 'closed') {
            // Обновляем данные и во временном объекте (т.к. статус документа изменится на не редактируемый и будет возможность только отмены изменений)
            this.documentService.tempData[this.document.guid] = _.cloneDeep(this.data);
        }
        this.afterSendEnvelope.emit(true);
    }

    public saveEnvelopeTemplate() {
        this.envelopeDataComponent.processSaveTemplate.status = true;
        this.envelopeDataComponent.save(false, null, true);
        return this.envelopeDataComponent.processSaveTemplate;
    }
}
