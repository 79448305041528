<div class="tree-filter-container" [ngStyle]="{ height: scrollbarHeight }" *ngIf="hasTreeFilter && treeFilter">
    <tree-filter
        [enabled]="treeFilterEnabled"
        [treeFilter]="treeFilter"
        [treeLevelsToClear]="treeLevelsToClear"
        (onEnabledChange)="onTFEnabledChange($event)"
    >
    </tree-filter>
</div>
<div [ngStyle]="{ height: scrollbarHeight }" *ngIf="!hasTreeFilter">
    <evolenta-scrollbar [scrollClass]="'sidebar sidebar-secondary sidebar-default pb-20'">
        <div class="sidebar-content sidebar-compressed">
            <div class="tab-content">
                <div
                    class="tab-pane active"
                    *ngFor="let group of insideFilters; let groupNum = index"
                    id="{{ group.groupId }}"
                >
                    <ng-container *ngFor="let categoryFilter of group.data; let idx = index">
                        <div class="sidebar-category" *ngIf="!categoryFilter.isHidden">
                            <ng-container *ngIf="categoryFilter.type && categoryFilter.type === 'button'">
                                <div *ngIf="categoryFilter.isLoadFile">
                                    <input
                                        id="file{{ groupNum }}{{ idx }}"
                                        class="inputfile hide"
                                        type="file"
                                        name="file"
                                        (change)="buttonFileChange($event, categoryFilter)"
                                    />
                                    <label
                                        for="file{{ groupNum }}{{ idx }}"
                                        class="btn no-white-space bg-blue-700 width-100 text-size-large"
                                    >
                                        {{ categoryFilter.name }}
                                    </label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!categoryFilter.type || categoryFilter.type !== 'button'">
                                <div class="category-title">
                                    <span>
                                        {{ categoryFilter.name }}
                                    </span>
                                    <ul class="icons-list" (click)="isCollapsed('group' + groupNum + '' + idx, $event)">
                                        <li><a data-action="collapsed"></a></li>
                                    </ul>
                                </div>
                            </ng-container>
                            <div class="category-content group{{ groupNum + '' + idx }}">
                                <ng-container
                                    *ngFor="let filter of categoryFilter.filters; let fidx = index; let fst = first"
                                >
                                    <!-- Простой поиск  (INPUT) -->
                                    <div class="mt-10" *ngIf="filter.type == 'input'">
                                        <div
                                            class="has-feedback has-feedback-left form-group-lg"
                                            [ngClass]="{ 'mt-5': !fst }"
                                        >
                                            <label *ngIf="filter.inputLabel" class="text-bold text-size-small"
                                                >{{ filter.inputLabel }}:</label
                                            >
                                            <form>
                                                <input
                                                    class="form-control"
                                                    type="{{ filter.isNumber ? 'number' : 'text' }}"
                                                    name="filterItem{{ fidx }}"
                                                    placeholder="{{ filter.placeholder }}"
                                                    [textMask]="{ mask: getMask(filter.mask) }"
                                                    *ngIf="filter.mask"
                                                    [(ngModel)]="filter.value"
                                                    (ngModelChange)="changeInputValue($event, filter, categoryFilter)"
                                                />
                                                <input
                                                    type="{{ filter.isNumber ? 'number' : 'text' }}"
                                                    class="form-control"
                                                    name="filterItem{{ fidx }}"
                                                    [readOnly]="filter.disabled"
                                                    placeholder="{{ filter.placeholder }}"
                                                    *ngIf="!filter.mask"
                                                    [(ngModel)]="filter.value"
                                                    (ngModelChange)="changeInputValue($event, filter, categoryFilter)"
                                                />
                                            </form>

                                            <div class="form-control-feedback">
                                                <i class="icon-search4 text-size-base text-muted"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Множественный выбор (CHECKBOXES) -->
                                    <ng-container *ngIf="filter.type == 'checkboxes'">
                                        <!-- Если у элемента есть родительские значения -->
                                        <ng-container *ngIf="filter.parent">
                                            <div class="radio-inline" *ngFor="let parent of filter.parent">
                                                <input
                                                    type="radio"
                                                    class="radio"
                                                    name="parent-filter"
                                                    [(ngModel)]="filter.parentValue"
                                                    [value]="parent"
                                                    id="parentFilter{{ parent.code }}"
                                                    (change)="changeParentValue(filter)"
                                                />
                                                <label for="parentFilter{{ parent.code }}">{{ parent.name }}</label>
                                            </div>
                                            <hr class="mt-10 mb-15" />
                                        </ng-container>
                                        <!-- Если элементы разбиты на подгруппы -->
                                        <ng-container *ngIf="filter.itemGroups">
                                            <ng-container
                                                *ngFor="
                                                    let itemGroup of filter.itemGroups;
                                                    let gidx = index;
                                                    let gfst = first
                                                "
                                            >
                                                <div
                                                    class="text-muted mb-10 text-uppercase text-size-mini"
                                                    [ngClass]="{ 'mt-20': !gfst }"
                                                >
                                                    {{ itemGroup.name }}
                                                </div>
                                                <ng-container *ngFor="let item of filter.items; let sub = index">
                                                    <div
                                                        class="checkbox-block"
                                                        *ngIf="isShowCheckBlock(filter, item, sub)"
                                                    >
                                                        <input
                                                            class="checkbox"
                                                            name="items{{ idx }}"
                                                            type="checkbox"
                                                            id="groupFilterCheckbox{{ idx }}{{ fidx }}{{ gidx }}{{
                                                                sub
                                                            }}"
                                                            [checked]="item.isChecked"
                                                            (click)="selectCheckboxItem(filter, item)"
                                                        />
                                                        <label
                                                            for="groupFilterCheckbox{{ idx }}{{ fidx }}{{ gidx }}{{
                                                                sub
                                                            }}"
                                                        >
                                                            <div
                                                                style="font-size: 9px; border-radius: 2px"
                                                                [ngStyle]="{
                                                                    width: filter.labelWidth
                                                                        ? filter.labelWidth
                                                                        : '105px'
                                                                }"
                                                                *ngIf="filter.itemStyleProperty"
                                                                class="pl-5 pr-5 pt-3 pb-3 text-uppercase text-center"
                                                                [ngClass]="item[filter.itemStyleProperty]"
                                                            >
                                                                {{ item[filter.itemName] }}
                                                            </div>
                                                            <span *ngIf="!filter.itemStyleProperty">{{
                                                                filter.itemName ? item[filter.itemName] : item.name
                                                            }}</span>
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                        <!-- Если элементы не разбиты на подгруппы -->
                                        <ng-container *ngIf="!filter.itemGroups">
                                            <ng-container *ngFor="let item of filter.items; let sub = index">
                                                <!-- Свертываемый элемент -->
                                                <div class="checkbox-block" *ngIf="isShowCheckBlock(filter, item)">
                                                    <input
                                                        class="checkbox"
                                                        name="items{{ idx }}{{ fidx }}"
                                                        type="checkbox"
                                                        id="filterCheckboxRepeat{{ idx }}{{ fidx }}{{ sub }}"
                                                        [checked]="item.isChecked"
                                                        (click)="selectCheckboxItem(filter, item, true)"
                                                    />
                                                    <label for="filterCheckboxRepeat{{ idx }}{{ fidx }}{{ sub }}">
                                                        <div
                                                            style="font-size: 9px; border-radius: 2px"
                                                            [ngStyle]="{
                                                                width: filter.labelWidth
                                                                    ? filter.labelWidth.toString() + 'px'
                                                                    : '105px'
                                                            }"
                                                            *ngIf="filter.itemStyleProperty"
                                                            class="pl-5 pr-5 pt-3 pb-3 text-uppercase text-center display-inline-block"
                                                            [ngClass]="item[filter.itemStyleProperty]"
                                                        >
                                                            {{ item[filter.itemName] }}
                                                        </div>
                                                        <span *ngIf="!filter.itemStyleProperty">
                                                            {{ filter.itemName ? item[filter.itemName] : item.name }}
                                                        </span>
                                                    </label>
                                                    <a
                                                        class="control-arrow text-slate ml-5"
                                                        [attr.title]="item.showHidden ? 'Свернуть' : 'Развернуть'"
                                                        (click)="toggleHidden(filter, item)"
                                                    >
                                                        <i
                                                            [ngClass]="{
                                                                'icon-circle-down2': item.isShow,
                                                                'icon-circle-left2': !item.isShow
                                                            }"
                                                        ></i>
                                                    </a>
                                                </div>

                                                <div
                                                    class="checkbox-block"
                                                    [ngClass]="{
                                                        'ml-30': item.pearentCode,
                                                        'checkbox-margin': item.isHierarchical,
                                                        hierarchical: filter.isHierarchical
                                                    }"
                                                    *ngIf="
                                                        (!filter.isHierarchical &&
                                                            showCheckboxByVisibility(filter, item, sub) &&
                                                            showCheckboxByParent(filter, item)) ||
                                                        (filter.isHierarchical && item.isShown)
                                                    "
                                                >
                                                    <input
                                                        id="filterCheckbox{{ idx }}{{ fidx }}{{ sub }}"
                                                        class="checkbox"
                                                        name="items{{ idx }}{{ fidx }}"
                                                        type="checkbox"
                                                        [checked]="item.isChecked"
                                                        (click)="selectCheckboxItem(filter, item)"
                                                    />
                                                    <label for="filterCheckbox{{ idx }}{{ fidx }}{{ sub }}">
                                                        <div
                                                            style="
                                                                font-style: normal;
                                                                font-weight: 500;
                                                                font-size: 10px;
                                                                line-height: 15px;
                                                                border-radius: 2px;
                                                            "
                                                            [ngStyle]="{
                                                                width: filter.labelWidth
                                                                    ? filter.labelWidth.toString() + 'px'
                                                                    : '105px'
                                                            }"
                                                            *ngIf="filter.itemStyleProperty"
                                                            class="pl-5 pr-5 pt-3 pb-2 text-uppercase text-center display-inline-block"
                                                            [ngClass]="item[filter.itemStyleProperty]"
                                                        >
                                                            {{ item[filter.itemName] }}
                                                        </div>
                                                        <span *ngIf="!filter.itemStyleProperty">{{
                                                            filter.itemName ? item[filter.itemName] : item.name
                                                        }}</span>
                                                    </label>
                                                    <i
                                                        class="arrow-format cursor-pointer"
                                                        *ngIf="filter.isHierarchical && item.isMain"
                                                        (click)="hideHierarchyChildren(filter, item)"
                                                        [ngClass]="{
                                                            'icon-arrow-down22': item.showHidden,
                                                            'icon-arrow-left22': !item.showHidden
                                                        }"
                                                    >
                                                    </i>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="filter.countVisible">
                                                <span
                                                    class="anchor text-info mt-10"
                                                    *ngIf="!filter.showAll"
                                                    (click)="filter.showAll = !filter.showAll"
                                                    >показать все</span
                                                >
                                                <span
                                                    class="anchor text-info mt-10"
                                                    *ngIf="filter.showAll"
                                                    (click)="filter.showAll = !filter.showAll"
                                                    >свернуть</span
                                                >
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>

                                    <!-- Период (сегодня, месяц, интервал и т.д.) -->
                                    <ng-container *ngIf="filter.type == 'period'">
                                        <div
                                            class="mb-10 mt-15"
                                            *ngIf="filter.value && filter.dates && filter.dates.length > 1"
                                        >
                                            <ng-select
                                                [allowClear]="false"
                                                [items]="filter.dates"
                                                [active]="filter.periodType ? [filter.periodType] : []"
                                                (selected)="selectPeriodType($event, filter)"
                                                placeholder="Выберите тип даты"
                                            >
                                            </ng-select>
                                        </div>
                                        <div class="radio-block" *ngFor="let item of filter.items; let iidx = index">
                                            <input
                                                type="radio"
                                                name="period{{ idx }}{{ fidx }}"
                                                class="radio"
                                                id="period{{ idx }}{{ fidx }}{{ iidx }}"
                                                [checked]="filter?.value?.code == item.code"
                                                [value]="item"
                                                (click)="changeRadioValue(item, filter)"
                                            />
                                            <label for="period{{ idx }}{{ fidx }}{{ iidx }}">{{ item.name }}</label>
                                        </div>
                                        <div
                                            class="mt-10"
                                            *ngIf="filter.value?.code == 'interval' || filter.onlyPeriod"
                                        >
                                            <ng-container *ngIf="moduleBaseUrl === complaintsBasePath">
                                                <div class="mb-10">
                                                    <evolenta-datepicker
                                                        [large]="true"
                                                        [(ngModel)]="filter.intervalStart"
                                                        [valueAsString]="true"
                                                        (ngModelChange)="beforeApplyFilters()"
                                                        placeholder="c ..."
                                                    >
                                                    </evolenta-datepicker>
                                                </div>
                                                <evolenta-datepicker
                                                    [large]="true"
                                                    [(ngModel)]="filter.intervalEnd"
                                                    [valueAsString]="true"
                                                    (ngModelChange)="beforeApplyFilters()"
                                                    placeholder="по ..."
                                                >
                                                </evolenta-datepicker>
                                            </ng-container>
                                            <ng-container *ngIf="moduleBaseUrl !== complaintsBasePath">
                                                <div class="mb-10">
                                                    <evolenta-datepicker
                                                        [large]="true"
                                                        [(ngModel)]="filter.intervalStart"
                                                        [valueAsString]="false"
                                                        (ngModelChange)="beforeApplyFilters()"
                                                        placeholder="c ..."
                                                    >
                                                    </evolenta-datepicker>
                                                </div>
                                                <evolenta-datepicker
                                                    [large]="true"
                                                    [(ngModel)]="filter.intervalEnd"
                                                    [valueAsString]="false"
                                                    (ngModelChange)="beforeApplyFilters()"
                                                    placeholder="по ..."
                                                >
                                                </evolenta-datepicker>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="filter.type == 'select'">
                                        <div class="form-group form-group-lg">
                                            <ng-select
                                                [allowClear]="true"
                                                [items]="filter.items"
                                                (selected)="selectSelectType($event, filter)"
                                                (removed)="selectSelectType({}, filter)"
                                                placeholder="Выберите из списка"
                                                class="mt-15"
                                            >
                                            </ng-select>
                                        </div>
                                    </ng-container>

                                    <!-- Единичный выбор (RADIOBOXES) -->
                                    <ng-container *ngIf="filter.type == 'radioboxes'">
                                        <div
                                            [class]="filter.inline ? 'radio-inline' : 'radio-block'"
                                            *ngFor="let item of filter.items; let iidx = index"
                                        >
                                            <input
                                                type="radio"
                                                name="radio{{ idx }}{{ fidx }}"
                                                class="radio"
                                                id="radio{{ idx }}{{ fidx }}{{ iidx }}"
                                                [checked]="filter.value && filter.value.code == item.code"
                                                [value]="item"
                                                (click)="changeRadioValue(item, filter)"
                                            />
                                            <label for="radio{{ idx }}{{ fidx }}{{ iidx }}">{{
                                                filter['labelName'] ? item[filter.labelName] : item.name
                                            }}</label>
                                        </div>
                                    </ng-container>

                                    <!-- Единичный чекбокс -->
                                    <ng-container *ngIf="filter.type == 'checkbox'">
                                        <div class="checkbox-block" [ngClass]="{ 'mb-20': filter.marginAfter }">
                                            <input
                                                id="cb{{ groupNum }}{{ idx }}{{ fidx }}"
                                                class="checkbox"
                                                type="checkbox"
                                                [value]="true"
                                                [checked]="filter.value"
                                                (click)="changeCheckboxValue(filter)"
                                            />
                                            <label for="cb{{ groupNum }}{{ idx }}{{ fidx }}">{{
                                                filter.placeholder
                                            }}</label>
                                        </div>
                                    </ng-container>

                                    <!-- Дата (DATAPICKER) -->
                                    <div [ngClass]="{ 'mt-5': !fst }" *ngIf="filter.type == 'date'">
                                        <evolenta-datepicker
                                            [(ngModel)]="filter.value"
                                            [valueAsString]="!!filter.valueAsString"
                                            (ngModelChange)="changeDateValue(filter, categoryFilter)"
                                            [canClear]="true"
                                            [large]="true"
                                        ></evolenta-datepicker>
                                    </div>

                                    <!-- Элемент-справочник -->
                                    <div class="mt-10" *ngIf="filter.type == 'dictionary'">
                                        <catalogue
                                            name="dictionaryFilter{{ fidx }}"
                                            size="5"
                                            [catalogName]="filter.dictionary.name"
                                            [priority]="true"
                                            [large]="true"
                                            [showCode]="false"
                                            [nameField]="filter.nameField ? filter.nameField : 'name'"
                                            [baseSearch]="prepareDictionaryBaseSearch(filter)"
                                            [(ngModel)]="filter.value"
                                            (onSelect)="selectDictionaryValue($event, filter)"
                                            (onClear)="clearDictionaryValue($event, filter)"
                                        >
                                        </catalogue>

                                        <!-- для dictionary сохраненных фильтров - filter.underType === 'savedFilters' и БЕЗ filterName-->
                                        <ng-container *ngIf="filter.underType === 'savedFilters'">
                                            <div class="mt-20">
                                                <ng-container *ngIf="arrSavedFilters.length > 0 && isEditFilter">
                                                    <div class="list-item-data">
                                                        <div
                                                            *ngFor="let filterS of arrSavedFilters; let idx = index"
                                                            class="mt-5 ml-5"
                                                        >
                                                            <div class="flex" style="justify-content: space-between">
                                                                <div class="text-muted">
                                                                    {{ idx + 1 + '. ' + filterS.name }}
                                                                </div>
                                                                <button
                                                                    class="btn btn-main btn-xs ml-5"
                                                                    style="width: 25px; padding: 0"
                                                                    (click)="deleteUserFilter(filterS._id)"
                                                                >
                                                                    <i class="icon-trash"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <div
                                                    *ngIf="arrSavedFilters.length !== 0"
                                                    class="flex"
                                                    [class.mt-5]="!isEditFilter || arrSavedFilters.length > 0"
                                                >
                                                    <button
                                                        class="btn btn-main btn-info btn-xs text-uppercase"
                                                        [disabled]="!isActiveFilters() || arrSavedFilters.length === 0"
                                                        (click)="saveUserFilter(filter)"
                                                    >
                                                        Сохранить фильтр
                                                    </button>
                                                    <button
                                                        *ngIf="arrSavedFilters.length > 0"
                                                        class="btn btn-main btn-info btn-xs text-uppercase ml-5"
                                                        style="width: 50px; padding: 0"
                                                        (click)="isEditFilter = !isEditFilter"
                                                    >
                                                        <i class="icon-pencil3"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <!-- Множественный список с ручным вводом -->
                                    <ng-container *ngIf="filter.type == 'multipleListWithManualInput'">
                                        <button
                                            type="button"
                                            class="btn btn-main mt-10 btn-success btn-xs text-uppercase display-flex justify-content-between align-items-center"
                                            *ngIf="!filter.value"
                                            (click)="addMultipleListItem(filter)"
                                        >
                                            <span>{{ filter.buttonName }}</span>
                                            <strong class="icon-plus-circle2"></strong>
                                        </button>
                                        <ng-container *ngIf="filter.value && filter.value.length > 0">
                                            <div class="row mt-10" *ngFor="let item of filter.value; let j = index">
                                                <div class="input-group input-group-xs">
                                                    <input
                                                        [type]="'text'"
                                                        [(ngModel)]="item.value"
                                                        (ngModelChange)="beforeApplyFilters()"
                                                        class="form-control"
                                                    />
                                                    <div class="input-group-btn">
                                                        <button
                                                            class="btn btn-default"
                                                            type="button"
                                                            *ngIf="!j"
                                                            (click)="addMultipleListItem(filter)"
                                                        >
                                                            <i class="icon-plus-circle2"></i>
                                                        </button>
                                                        <button
                                                            class="btn btn-default"
                                                            type="button"
                                                            *ngIf="j"
                                                            (click)="deleteMultipleListItem(filter, j)"
                                                        >
                                                            <i class="icon-trash"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <!-- Кнопка фильтрации -->
            <button
                *ngIf="isActiveFilters()"
                class="btn btn-main ml-20 mr-20 btn-success btn-xs text-uppercase"
                (click)="clearFilters()"
            >
                Очистить
            </button>

            <ng-container *ngIf="moduleBaseUrl === 'complaints' && isActiveFilters() && arrSavedFilters.length === 0">
                <div class="flex ml-20 mr-20 mt-20">
                    <button class="btn btn-main btn-info btn-xs text-uppercase" (click)="saveUserFilter(null)">
                        Сохранить фильтр
                    </button>
                </div>
            </ng-container>
        </div>
    </evolenta-scrollbar>
</div>
