import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AccessService,
    FilesService,
    HttpService,
    RestService,
    StorageService,
    ToasterService,
    TranslateService,
} from '@evolenta/core';
import { ByteUtilities } from '@evolenta/utilities';
import { CertificatesService } from '../../../../../../../common/services/certificates.service';
import { CadesSignModalComponent } from '@evolenta/signing';
import { DocumentService } from '../../document.service';
import { ClientModuleService } from '../../../../../../../common/services/client-module.service';
import { PrintFormsService } from '../../../../../../../common/services/print/print-forms.service';
import { Permission } from '../../../../../../../common/services/permission';
import { AppealSaveService } from '../../../../appeal-save.service';
import { Config } from '../../../../../../../common/services/config';
import { EnvelopesService } from '../../../../../../../common/services/envelopes.service';
import cloneDeep from 'lodash-es/cloneDeep';
import * as FileSaver from 'file-saver';
import { RsoService } from '../../../../../../../common/services/rso.service';

@Component({
    selector: 'appeal-document-files',
    templateUrl: 'appeal-document-files.component.html',
})
export class AppealDocumentFilesComponent implements OnInit, AfterViewInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onDeleteFile = new EventEmitter<boolean>();

    public file4Sign = null; // Файл, отправленный на подписание
    public file4VerifySign = null; // Файл, отправленный для КНМ подписи

    @ViewChild(CadesSignModalComponent, { static: false }) public cadesSignModalComponent: CadesSignModalComponent;

    public data: any = {}; // описательный объект для документа

    public permissions = Permission; // Набор прав системы
    public uploadedFileMaxSize = 0;
    public uploadedFileMaxSizeInMb = 0;
    public isProcessingScan = false;
    public localizations;
    public userEnvelopesMode = this.storage.getItem('userEnvelopesMode');
    public applications = this.storage.getItem('applications');
    public user = this.storage.getItem('user');
    public isDisabledByRso = false;

    public constructor(
        public accessService: AccessService,
        public documentService: DocumentService,
        private filesService: FilesService,
        private toaster: ToasterService,
        private clientModuleService: ClientModuleService,
        private printFormsService: PrintFormsService,
        private appealSaveService: AppealSaveService,
        private storage: StorageService,
        private restService: RestService,
        private httpService: HttpService,
        private translate: TranslateService,
        private rsoService: RsoService,
    ) {}

    public async ngOnInit(): Promise<void> {
        this._loadTranslations();
        // Максимальный размер файла для загрузки
        if (this.group && this.group.maxFileSize && this.group.maxFileSize > 0) {
            this.uploadedFileMaxSizeInMb = this.group.maxFileSize;
            this.uploadedFileMaxSize = this.group.maxFileSize * 1024 * 1024;
        } else {
            const uploadedFileMaxSize = this.storage.getItem('uploadedFileMaxSize');

            if (uploadedFileMaxSize === undefined || uploadedFileMaxSize === null) {
                const setting: any[] = await this.restService.search('settings', {
                    search: { search: [{ field: 'name', operator: 'eq', value: 'uploadedFileMaxSize' }] },
                });

                if (setting && setting.length > 0) {
                    const sizeInMb = parseInt(setting[0].value, 10);
                    const maxSizeInBt = sizeInMb * 1024 * 1024;
                    this.uploadedFileMaxSizeInMb = sizeInMb;
                    this.uploadedFileMaxSize = maxSizeInBt;
                    this.storage.setItem('uploadedFileMaxSize', maxSizeInBt);
                } else {
                    this.storage.setItem('uploadedFileMaxSize', 0);
                }
            } else {
                this.uploadedFileMaxSize = uploadedFileMaxSize;
                this.uploadedFileMaxSizeInMb = this.uploadedFileMaxSize / (1024 * 1024);
            }
        }

        this.data = this.documentService.data[this.document.guid];

        this.isDisabledByRso = this.rsoService.canNotEditAppeal(this.documentService.appeal);
        this.allowEdit = !this.isDisabledByRso;
    }

    private _loadTranslations(): void {
        this.translate.get(['common', 'appeals.documents']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    public allowFileAccess(file: any): boolean {
        return (
            !this.userEnvelopesMode ||
            file.entryName !== 'envelopes' ||
            !this.data.envelope ||
            EnvelopesService.AllowAccess(this.user, this.data.envelope, this.applications)
        );
    }

    public getMustNotExceedMessage(): string {
        return this.localizations['appeals.documents'].file_must_not_exceed.replace('%s', this.uploadedFileMaxSizeInMb);
    }

    public ngAfterViewInit(): void {
        // Корректировка флага наличия непросмотренного результата в запросе и в деле
        setTimeout(() => {
            this.correctEnvelopeAndAppealHavingResultFlag();
        });
    }

    /**
     * Добавление файла в очередь загрузки
     * @param event
     */
    public fileChange(event): void {
        const filesList = event.target.files;

        if (filesList.length > 0) {
            Array.from(filesList).forEach((file: any) => {
                if (this.isValidFileFormat(file) && this.isValidFileMaxSize(file)) {
                    this.data.queue.push(file);
                }
            });
        }
    }

    private isValidFileMaxSize(file: any): boolean {
        if (this.uploadedFileMaxSize !== 0 && file.size > this.uploadedFileMaxSize) {
            this.toaster.error(
                `Файл ${file.name} не может быть загружен! Размер файла превышает ${this.uploadedFileMaxSizeInMb}Мб`,
            );

            return false;
        }

        return true;
    }

    private isValidFileFormat(file: any): boolean {
        // Если указан допустимый формат файла
        const availableFormat = [];

        if (Array.isArray(this.group.docs) && this.group.docs.length > 0) {
            this.group.docs.forEach(doc => {
                if (doc.extensionTypes) {
                    availableFormat.push(doc.extensionTypes.split(','));
                }
            });
        }

        if (this.group.scanFormat || availableFormat.length > 0) {
            let fileExt;
            let formatText = this.group.scanFormat;
            const nameArr = file.name.split('.');

            if (availableFormat.length > 0) {
                formatText = !this.group.scanFormat
                    ? availableFormat.join(',')
                    : (formatText += ',' + availableFormat.join(','));
            }

            if (nameArr.length > 1) {
                fileExt = nameArr[nameArr.length - 1].toLowerCase();
                if (!formatText.includes(fileExt)) {
                    this.toaster.error(
                        `Недопустимый формат файла: ${fileExt}. Допустим для выбора файл в формате: ${formatText}`,
                    );

                    return false;
                }
            }
        }

        return true;
    }

    /**
     * Открытие файла (скачивание) еще не сохраненного на сервере
     * @param file
     */
    public openFile(file): void {
        FileSaver.saveAs(file, file.name);
    }

    private checkFileIsRequestXml(file): boolean {
        if (this.document.requestId) {
            const fileName = file.name;
            const arr = fileName.split('.');
            const fileExtension = arr[arr.length - 1].toLowerCase();

            return fileExtension === 'xml';
        }

        return false;
    }

    /**
     * Скачать прикрепленный сохраненный файл
     * @param id - идентификатор сохраненного файла
     * @param originalName - имя файла
     */
    public downloadFile(id, originalName): void {
        this.filesService.downloadAndSaveFile(id, originalName);
    }

    /**
     * Подписание файла
     * @param file - подписываемый файл
     */
    public signFile(file): void {
        this.file4Sign = file;
        this.filesService.downloadFileInBase64(this.file4Sign._id, 'text').then(
            (fileInBase64: any) => {
                this.cadesSignModalComponent.commonSignData(fileInBase64, false, 'file', this.file4Sign.originalName);
            },
            (error: any) => {
                this.toaster.error(error);
            },
        );
    }

    /**
     * Обработка результата подписания для запроса
     * @param result - {certificate: ...., signature: .....}
     */
    public async onSignatureComplete(result?) {
        if (!result) {
            return;
        }

        // Сохраняем подпись и сертификат для документа
        this.file4Sign.certificate = result.certificate;
        this.file4Sign.signature = result.signature;

        if (result.certificateData) {
            this.file4Sign.certificateData = result.certificateData;
        }

        const fileIndex = this.data.queue.findIndex(item => item._id === this.file4Sign._id);

        this.data.queue[fileIndex] = this.file4Sign;
        this._clearDocumentsInBaseAppeal(this.data.guid, fileIndex);

        await this.appealSaveService.saveAppeal();
    }

    private _clearDocumentsInBaseAppeal(guid, fileIndex) {
        this.appealSaveService.appeal.documents = this.appealSaveService.appeal.documents.map(document => {
            if (document.guid === this.data.guid) {
                document.files = cloneDeep(this.data.queue);
            }

            return document;
        });
        const baseAppeal = this.storage.getItem('baseAppeal');
        baseAppeal.documents = baseAppeal.documents.map(document => {
            if (document.guid === guid) {
                delete document.files[fileIndex].certificate;
                delete document.files[fileIndex].signature;
                delete document.files[fileIndex].certificateData;
            }

            return document;
        });
        this.storage.setItem('baseAppeal', baseAppeal);
    }

    /**
     * Открытие сертификата
     * @param certificate -  сертификат в base64
     */
    public openCertificate(certificate): void {
        const data = new Blob([certificate]);

        FileSaver.saveAs(data, 'certificate.cer');
    }

    /**
     * КНМ подписи файла
     * @param file - файл
     */
    public verifySignFile(file): void {
        this.file4VerifySign = file;
        this.filesService.downloadFileInBase64(this.file4VerifySign._id, 'text').then(
            (fileInBase64: any) => {
                this.cadesSignModalComponent.verifyCadesBesSign(this.file4VerifySign.signature, fileInBase64);
            },
            (error: any) => {
                this.toaster.error(error);
            },
        );
    }

    /**
     * Удаление прикрепленного файла из документа
     * @param file - удаляемый файл
     */
    public removeFile(file): void {
        this.data.queue = this.data.queue.filter(f => f.guid !== file.guid);

        if (
            this.documentService.data[this.data.guid] &&
            this.documentService.data[this.data.guid].queue &&
            this.data.queue.length !== this.documentService.data[this.data.guid].queue.length
        ) {
            // #CIT-1328

            this.documentService.data[this.data.guid].queue = this.documentService.data[this.data.guid].queue.filter(
                f => f.guid !== file.guid,
            );
        }
        this.document.files = this.document.files.filter(f => f.guid !== file.guid);

        this.onDeleteFile.emit(true);
    }

    /**
     * Сканирование файлов с помощью клиентского модуля
     */
    public scanFiles(): void {
        this.isProcessingScan = true;
        this.clientModuleService.scanDocument(this.documentService.appeal._id, this.group.scanFormat).then(
            (result: any) => {
                // Если в результате есть, что сохранять, то выставляем признак массового сохранения
                let amount = 0;
                let errorTransfer;

                result.forEach((scanResultItem: any) => {
                    if (scanResultItem.isMultipartTransfer) {
                        if (scanResultItem.isSuccessMultipartTransfer) {
                            this.data.queue.push(scanResultItem.savedFile);

                            if (scanResultItem.totalSheets) {
                                amount = amount + scanResultItem.totalSheets;
                            }
                        } else {
                            errorTransfer = scanResultItem.transferError;
                        }
                    } else {
                        this.data.queue.push(
                            new File([ByteUtilities.B64ToBlob(scanResultItem.content, '', 512)], scanResultItem.name),
                        );

                        if (scanResultItem.totalSheets) {
                            amount = amount + scanResultItem.totalSheets;
                        }
                    }
                });
                if (this.group.copy) {
                    this.data.copiesSheets = this.data.copiesSheets + amount;

                    if (this.data.copies === 0) {
                        this.data.copies = 1;
                    }
                } else {
                    this.data.originalsSheets = this.data.originalsSheets + amount;

                    if (this.data.originals === 0) {
                        this.data.originals = 1;
                    }
                }
                this.isProcessingScan = false;

                if (errorTransfer) {
                    this.toaster.error(errorTransfer);
                }
            },
            () => {
                this.isProcessingScan = false;
            },
        );
    }

    /**
     * Обработка результата КНМ подписи Cades BES
     * @param result -  - {isValid: ....}
     */
    public onVerifyCadesBesSignComplete(result?): void {
        if (result) {
            this.file4VerifySign.isSignValid = result.isValid;
        }
    }

    /**
     * Получение информации о подписи
     * @param file
     */
    public getFileCertificateInfo(file): any {
        return CertificatesService.GetFileCertificateInfo(file);
    }

    /**
     * Повторная генерация результирующего файла PDF для скачивания без прикрепления к запросу
     */
    public generateResultPdfForResultDoc(fileEnvelopeDocGroupGuid): void {
        this.printFormsService.generateEnvelopeResultPDF(this.data.envelope._id, fileEnvelopeDocGroupGuid);
    }

    /**
     * Корректировка флага наличия непросмотренного результата в запросе и в деле
     */
    private correctEnvelopeAndAppealHavingResultFlag(): void {
        if (this.data.envelope && this.data.envelope.isHavingResult) {
            this.data.envelope.isHavingResult = false;
            // КНМ наличия флага для других запросов
            let isHavingResultInAppeal = false; // наличие непросмотренных результатов в других запросах
            Object.keys(this.documentService.data).forEach((documentGuid: string) => {
                const documentData = this.documentService.data[documentGuid];
                if (documentData.envelope && documentData.envelope.isHavingResult) {
                    isHavingResultInAppeal = true;
                }
            });
            this.documentService.appeal.isHavingResult = isHavingResultInAppeal;
            // Заменяем данные, чтобы при нажатии кнопки "Закрыть" они не заменились
            this.documentService.tempData = cloneDeep(this.documentService.data);
            // Сохраняем изменения в конверте и в деле (если в этом есть необходимость - все результаты во всех запросах обработаны)

            this.appealSaveService.saveAppeal();
        }
    }

    public downloadFileSignature(file): void {
        if (file.signature) {
            const data = new Blob([file.signature]);

            FileSaver.saveAs(data, 'signature.sig');
        }
    }

    public isAllowCreatePdfWithSignature(file): boolean {
        const formats = [
            'odt',
            'ott',
            'fodt',
            'docx',
            'dotx',
            'doc',
            'html',
            'xhtml',
            'rtf',
            'txt',
            'sxw',
            'wpd',
            'ods',
            'ots',
            'fods',
            'xlsx',
            'xltx',
            'xls',
            'csv',
            'tsv',
            'sxc',
            'odp',
            'otp',
            'fodp',
            'pptx',
            'ppt',
            'sxi',
            'odg',
            'otg',
            'fodg',
            'pdf',
            'swf',
            'vsdx',
            'vsd',
            'bmp',
            'gif',
            'jpg',
            'jpeg',
            'png',
            'svg',
            'tif',
            'tiff',
        ];
        const fileNameData = file.originalName.split('.');
        const extension = fileNameData[fileNameData.length - 1].toLowerCase();

        return formats.indexOf(extension) !== -1;
    }

    public createPdfWithSignatureFile(file): void {
        this.httpService
            .getContentFile(
                Config.server + Config.api + 'rendering/transformToPdf/intFileWithSig?fileId=' + file._id,
                'blob',
            )
            .then(
                (response: any) => {
                    const fileName = file.originalName;
                    const fileNameArr = fileName.split('.');
                    const clearFileName = fileNameArr.slice(0, fileNameArr.length - 1).join('.');

                    const data = new Blob([response]);
                    FileSaver.saveAs(data, clearFileName + '_signed.pdf');
                },
                (error: any) => {
                    if (error.status === 500) {
                        this.toaster.error('Технический сбой. Просьба обновить вложения и переподписать документ');
                    }
                },
            );
    }

    public getSignVerificationValue(file): string {
        switch (file.signVerificationResult) {
            case 'NOT_CONFIRMED':
                return 'Подпись недействительна';
            case 'EXPIRED':
                return 'Подпись подтверждена, но на момент проверки срок действия сертификата истек';
            case 'CONFIRMED':
                return 'Подпись подтверждена';
            default:
                return 'Файл без электронно-цифровой подписи';
        }
    }

    public getAssetForSignVerificationValue(file): string {
        switch (file.signVerificationResult) {
            case 'NOT_CONFIRMED':
                return 'assets/sign-icons/not-confirmed.svg';
            case 'EXPIRED':
                return 'assets/sign-icons/expired.svg';
            case 'CONFIRMED':
                return 'assets/sign-icons/confirmed.svg';
            default:
                return 'assets/sign-icons/without-sign.svg';
        }
    }
}
