import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AccessService, ModalDialogComponent, StorageService, ToasterService } from '@evolenta/core';
import { CommonAppealDocumentCommonComponent } from '../document-common/common-appeal-document-common.component';
import { CommonAppealDocumentPropertiesComponent } from '../document-properties/common-appeal-document-properties.component';
import { CommonAppealDocumentService } from '../../common-appeal-document.service';
import { CommonAppealValidateService } from '../../../../services/common-appeal-validate.service';
import { Permission } from '../../../../../../common/services/permission';
import { CommonAppealSaveService } from '../../../../services/common-appeal-save.service';
import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import { ErrorLoggingService } from '../../../../../knm/error-logging.service';

@Component({
    selector: 'common-appeal-document-card',
    templateUrl: 'common-appeal-document-card.component.html',
})
export class CommonAppealDocumentCardComponent implements OnInit, AfterViewInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public subservice; // услуга на основе которой формируется дело
    @Input() public sendEnvelope; // флаг необходимости отправки запроса из конверта сразу после иниициализации компонента
    @Input() public task;
    @Input() public allowEdit = true;
    @Input() public multiple = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onPrint = new EventEmitter<object>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public updateAppealAfterSendStatusEnvelope = new EventEmitter<boolean>();
    @Output() public isCheckFileSign = new EventEmitter<boolean>();

    public editTabs = [];
    public isProcessSelectDocumentKind = false; // Флаг отображения процесса выбора вида документа из списка доступных видов для группы
    public isProcessEditDocumentName = false; // Флаг редактирования наименования документа (произвольные документы)
    public isProcessSimplifyAddDocumentToComplex = false; // Флаг добавления документа в группу (документы комплексного запроса)
    public availableOtherDocuments = []; // Массив документов, доступных для выбора в виде ссылки
    public isProcessSelectAvailableDocument = false; // Флаг отображения процесса выбора документа, на который ссылается текущий документ

    public data; // описательный массив обрабатываемого документа дела
    public allDocumentsData; // описательный объект всех документов дела
    public startAfterSubservices = null; // Массив услуг, после которых стартует услуга обрабатываемого документа
    public isProcessSelectResultSubserviceLink = false; // Флаг отображения процесса выбора услуги, на результат которой ссылается текущий документ
    @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDialogComponent;

    public isProcessSendingEnvelope = false; // Флаг осуществления процесса отправки запроса
    @ViewChild('fileInput', { static: false }) public fileInput: any;
    @ViewChild('commonData', { static: false }) public documentCommonDataComponent: CommonAppealDocumentCommonComponent;
    @ViewChild('appealDocumentProperties', { static: false })
    public appealDocumentPropertiesComponent: CommonAppealDocumentPropertiesComponent;

    public continueProcessingAppeal; // флаг продолжения операции сохранения (перехода на другой статус) дела после применения изменений в услуги
    public currentOrganization;

    public permissions = Permission; // Набор прав системы

    // Произвольный документ
    public otherDocument = {
        guid: 'other',
        name: 'Произвольный документ',
    };

    public documentsForComplex = []; // Массив видов документов для упрощенного режима комплексного дела
    public selectedDocumentFromList; // Выбранный документ из списка

    public constructor(
        public accessService: AccessService,
        private documentService: CommonAppealDocumentService,
        private commonAppealSaveService: CommonAppealSaveService,
        private validateService: CommonAppealValidateService,
        private storageService: StorageService,
        private errorLoggingService: ErrorLoggingService,
        private toaster: ToasterService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.data = this.documentService.data[this.document.guid]; // описательный массив обарабатываемого документа
        this.allDocumentsData = this.documentService.data;
        this.currentOrganization = this.storageService.getItem('currentOrganization');
        if (this.mode === 'edit') {
            // Задание вкладки по умолчанию
            if (!this.activeTab) {
                this.activeTab = 'common';
            }

            // Инициализация режима выбора вида документа
            if (this.group.docs && !this.document.docGuid && !this.data.name) {
                this.isProcessSelectDocumentKind = true;
            }

            // Инициализация массива видов документов для выбора в составе набора документов комплексного запроса (упрощенный режим)
            if (this.appeal.simplifyMode) {
                this.generateDocumentsListForComplex();
            }

            // Инициализация режима ввода наименования документа (выбор из списка для документов комплекса)
            if (!this.group.guid && !this.data.name) {
                this.isProcessSelectDocumentKind = true;
                if (this.group.code) {
                    this.isProcessSimplifyAddDocumentToComplex = true;
                } else {
                    this.isProcessEditDocumentName = true;
                }
            }

            // КНМ доступных для выбора документов в виде документа-связи
            if (this.document.docGuid) {
                this._checkEnableOtherDocumentLink();
            }

            // Инициализация вкладок
            this.initEditTabs();

            // КНМ возможности создания ссылки на результат предоставления другой услуги
            this.checkEnableOtherSubserviceResultLink();

            // КНМ на уже запущенный процесс отправки запроса
            if (
                this.data.envelope &&
                this.data.envelope.resultSending &&
                this.data.envelope.resultSending.status &&
                (this.data.envelope.resultSending.status === 'process' ||
                    this.data.envelope.resultSending.status === 'needSign')
            ) {
                this.isProcessSendingEnvelope = true;
            }
        }
    }

    public ngAfterViewInit() {
        if (!this.data.doc && this.group.docs && this.group.docs.length === 1 && this.mode === 'edit') {
            this.selectDocumentKind(this.group.docs[0]);
            setTimeout(() => {
                this.apply();
            }, 100);
        }
    }

    /**
     * Формирование списка возможных видов документов для комплексного дела
     */
    public generateDocumentsListForComplex() {
        this.documentsForComplex = [];
        Object.keys(this.documentService.appealSubservicesData).forEach(appealSubserviceGuid => {
            const appealSubserviceGroups =
                this.documentService.appealSubservicesData[appealSubserviceGuid].appealDocumentGroups;
            appealSubserviceGroups.forEach(group => {
                group.docs.forEach(doc => {
                    if (!doc.requestId) {
                        const find = this.documentsForComplex.find(item => item.id === doc.code);
                        if (!find) {
                            this.documentsForComplex.push({ id: doc.code, text: doc.name });
                        }
                    }
                });
            });
        });

        this.documentsForComplex = sortBy(this.documentsForComplex, 'text');
    }

    /**
     * Выбор документа из списка всех услуг дела (комплексный запрос)
     * @param value
     */
    public selectDocumentForComplexFromList(value) {
        this.data.name = value.text;
        this.data.docCode = value.id;
        this.isProcessSelectDocumentKind = false;
        this.isProcessSimplifyAddDocumentToComplex = false;
    }

    /**
     * Нажатие ссылки "Изменить"
     */
    public changeDocument() {
        if (this.document.groupCode) {
            this.isProcessSimplifyAddDocumentToComplex = true;
            if (this.data.docCode) {
                const find = this.documentsForComplex.find(item => item.id === this.data.docCode);
                if (find) {
                    this.selectedDocumentFromList = find;
                }
            }
        } else {
            this.isProcessEditDocumentName = true;
        }
    }

    /**
     * Определение набора вкладок для редактирования информации в случае обычного документа или документа запроса
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'common',
                name: !this.document.requestId ? 'Общие данные' : 'Данные запроса',
                visible: true,
            },
            {
                code: 'files',
                name: 'Ответ',
                visible: this.document.requestId && !this.data.link && !this.data.resultSubserviceLink,
            },
            {
                code: 'properties',
                name: 'Дополнительные данные',
                visible:
                    !this.document.requestId &&
                    this.data.allowPartiallyEdit &&
                    !this.data.link &&
                    !this.data.resultSubserviceLink,
            },
        ];

        // Выставление флага активности для вкладки
        if (this.activeTab) {
            const find = this.editTabs.find(item => item.code === this.activeTab);
            if (find) {
                find.active = true;
            }
        }
    }

    /**
     * Выбор вида документа
     * @param doc
     */
    public selectDocumentKind(doc) {
        this.documentService.selectDocumentKind(this.document, this.data, doc);
        if (doc.guid !== 'other') {
            this.activeTab = 'common';
            this._checkEnableOtherDocumentLink();
            this.initEditTabs();
        } else {
            this.isProcessEditDocumentName = true;
        }
        this.isProcessSelectDocumentKind = false;
    }

    /**
     * КНМ возможности создания линка на документ из списка документов других услуг (набора документов из группы "Комплексный запрос") из состава комплексной услуги
     */
    public _checkEnableOtherDocumentLink() {
        this.availableOtherDocuments = [];
        if (this.appeal.subservices.length === 1) {
            return;
        }

        // Выборка возможных линков для документа который является результатом предоставления другой услуги
        if (this.data.resultSubserviceLink) {
            // Выбор документов, которые принадлежат услуге, от которой зависит текущий документ
            const documentsFromLinkSubservice = this.appeal.documents.filter(
                item => item.subserviceGuid === this.data.resultSubserviceLink.guid,
            );
            // Из полученного массива выбираем только те, которые принадлежат исходящим группам документов (isOutput = true)
            if (
                documentsFromLinkSubservice.length &&
                this.appeal.subservice.guid === this.data.resultSubserviceLink.guid
            ) {
                documentsFromLinkSubservice.forEach(document => {
                    if (
                        this.documentService.data[document.guid].group &&
                        this.documentService.data[document.guid].group.isOutput
                    ) {
                        this.availableOtherDocuments.push({
                            guid: document.guid,
                            name: document.name,
                            group: this.documentService.data[document.guid].group.name,
                            subservice: this.appeal.subservice.shortTitle,
                        });
                    }
                });
            }
        } else if (this.data.doc && this.data.doc.code) {
            // Выборка возможных линков документов при совпадении кода документа
            const availableDocuments = this.appeal.documents.filter(
                item =>
                    (item.docCode === this.data.doc.code && !item.link && item.guid !== this.document.guid) ||
                    (!item.docCode && item.groupCode === 'complex'),
            );
            if (availableDocuments.length > 0) {
                // Определяем услугу, к которой относится текущий документ
                let currentDocumentSubservice = null;
                const currentDocumentGroup = this.subservice.documentGroups.find(item => item.guid === this.group.guid);
                if (currentDocumentGroup) {
                    currentDocumentSubservice = this.subservice;
                }

                availableDocuments.forEach(availableDocument => {
                    if (availableDocument.subserviceGuid && this.subservice._id !== currentDocumentSubservice._id) {
                        const documentGroup = this.subservice.documentGroups.find(
                            item => item.guid === availableDocument.groupGuid,
                        );
                        if (documentGroup) {
                            this.availableOtherDocuments.push({
                                guid: availableDocument.guid,
                                name: availableDocument.name,
                                group: documentGroup.name,
                                subservice: this.subservice.titles.shortTitle,
                            });
                        }
                    } else {
                        const documentForAdd = {
                            guid: availableDocument.guid,
                            name: availableDocument.name,
                            group: availableDocument.groupName,
                            subservice: null,
                        };
                        this.availableOtherDocuments.push(documentForAdd);
                    }
                });
            }
        }
    }

    /**
     * КНМ возможности создания линка на результат предоставления другой услуги в комплексе услуг
     * (случай, когда в услуге присутствуют значения startAfterGuids)
     *
     */
    public checkEnableOtherSubserviceResultLink() {
        this.startAfterSubservices = [];
        if (!this.document.subserviceGuid) {
            return;
        }

        if (
            this.appeal.subservice.guid !== this.document.subserviceGuid &&
            (!this.appeal.subservice.startAfterGuids || !this.appeal.subservice.startAfterGuids.length)
        ) {
            return;
        }

        this.appeal.subservice.startAfterGuids.forEach(subserviceGuid => {
            if (this.appeal.subservice.guid === subserviceGuid) {
                this.startAfterSubservices.push({
                    guid: this.appeal.subservice.guid,
                    title: this.appeal.subservice.shortTitle,
                });
            }
        });
    }

    /**
     * Включение / выключение ссылки на услугу с результатом
     */
    public changeResultSubserviceLink() {
        // Отключение связки с услугой
        if (this.data.resultSubserviceLink) {
            delete this.data.resultSubserviceLink;
        } else {
            if (this.startAfterSubservices.length === 1) {
                this.data.resultSubserviceLink = this.startAfterSubservices[0];
            } else {
                this.isProcessSelectResultSubserviceLink = true;
            }
        }
        this.initEditTabs();
    }

    /**
     * Выбор услуги, на результат выполнения которой будет ссылаться обрабатываемый документ
     * @param subservice
     */
    public selectResultSubserviceLink(subservice) {
        this.data.resultSubserviceLink = subservice;
        this.isProcessSelectResultSubserviceLink = false;
        this.initEditTabs();
    }

    /**
     * Удаление связи с другим документом
     */
    public deleteDocumentLink() {
        delete this.data.link;
    }

    /**
     * Выбор документа дела, на который ссылается обрабатываемый документ
     * @param document
     */
    public selectDocumentLink(document) {
        this.data.link = document.guid;
        this.isProcessSelectAvailableDocument = false;
        this.initEditTabs();
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Инициализация режима редактирования документа
     */
    public edit() {
        this.onEdit.emit({ document: this.document, group: this.group });
    }

    /**
     * Применение изменений
     * @param continueProcessingAppeal - продолжить сохранение (или перевод на другой статус) дела после применения изменений
     */
    public apply(continueProcessingAppeal = false) {
        this.continueProcessingAppeal = continueProcessingAppeal;
        // если это документ-запрос и есть Форма доп.полей для запроса
        if (this.document.requestId && this.data.envelope.request.xsd) {
            this.documentCommonDataComponent.saveEnvelope();
        } else if (this.activeTab === 'properties') {
            this.appealDocumentPropertiesComponent.save(null, true);
        } else {
            this.onApply.emit({ document: this.document, continueProcessingAppeal: continueProcessingAppeal });
        }
    }

    /**
     * Сохранение формы дополнительных данных на странице реквизитов (если они есть)
     * @param data
     */
    public afterSaveDocumentProperties(data) {
        // Переход на другую вкладку
        if (data.tab) {
            this.activeTab = data.tab;
        }
        // Сохранение изменений
        if (data.apply) {
            this.onApply.emit({ document: this.document, continueProcessingAppeal: this.continueProcessingAppeal });
        }
    }

    /**
     * Сохранение XSD-данных запроса в документ и приме
     */
    public afterGetEnvelopeXsdData() {
        this.onApply.emit({ document: this.document, continueProcessingAppeal: this.continueProcessingAppeal });
    }

    /**
     * Отмена изменений
     */
    public cancel() {
        this.onApply.emit(null);
    }

    /**
     * Активация вкладки при клике на закладку
     * @param tab - код выбранной вкладки
     */
    public activateTab(tab) {
        if (this.activeTab === 'properties') {
            // Если текущая вкладка "Доп. инфо", то перед переходом на новую сохраняем значения
            this.appealDocumentPropertiesComponent.save(tab);
        } else {
            this.activeTab = tab;
            if (this.mode === 'view') {
                this.onEdit.emit({ document: this.document, group: this.group, tab: tab });
            }
        }
    }

    /**
     * Инициализация удаления документа
     */
    public delete() {
        this.deleteModal.showModal();
    }

    /**
     * Обработка нажатия кнопок в модальном окне
     * @param type
     */
    public sendDeleteRequest(type) {
        if (type === 'yes') {
            this.onDelete.emit(this.document);
        }
    }

    /**
     * Формирование печатной формы
     */
    public print(printForm = null) {
        if (printForm) {
            this.document.printForm = cloneDeep(printForm);
            this.data.printForm = cloneDeep(printForm);
        } else {
            this.document.printForm = this.data.printForms[0];
            this.data.printForm = cloneDeep(this.data.printForms[0]);
        }
        if (this.mode === 'edit') {
            this.data.isProcessGeneratePrintForm = true;
            // Применяем данные документа с флагом последующего формирования печатной формы
            this.onApply.emit({
                document: this.document,
                continueProcessingAppeal: this.continueProcessingAppeal,
                applyForPrint: true,
            });
        } else {
            // Передаем управление в родительский элемент для формирования печатной формы
            this.onPrint.emit(this.document);
        }
    }

    /**
     * Отправка запроса (с предварительным сохранением дела)
     */
    public async send() {
        this.isProcessSendingEnvelope = true;

        // Применение изменений в текущем документе
        const documentIndex = this.appeal.documents.findIndex(item => item.guid === this.document.guid);
        const documentAppealData = this.documentService.generateDocumentDataToAppeal(this.document.guid);
        if (documentIndex !== -1) {
            this.appeal.documents[documentIndex] = documentAppealData; // Обновление данных документа
        } else {
            this.appeal.documents.push(documentAppealData); // Добавление документа в дело
        }
        // Сохранение данных дела на сервере

        try {
            await this.commonAppealSaveService.saveAppeal();
            // Обновление текущего документа
            const idx = this.appeal.documents.findIndex(item => item.guid === this.document.guid);
            this.document = cloneDeep(this.appeal.documents[idx]);
            this.documentService.tempData = cloneDeep(this.documentService.data);

            // Вызов метода отправки запроса из соответствующего компонента для документа
            await this.documentCommonDataComponent.processSendEnvelope();
        } catch (error) {
            this.toaster.error(error);
            await this.errorLoggingService.log(this.errorLoggingService.SPO, error, this.appeal);
        }
    }

    /**
     * Отмена статуса процесса отправки конверта после завершения отправки
     */
    public afterSendEnvelope() {
        if (
            (this.data.envelope.status === 'sended' || this.data.envelope.status === 'closed') &&
            this.data.isForwardingRequest
        ) {
            this.updateAppealAfterSendStatusEnvelope.emit(true);
        } else {
            this.isProcessSendingEnvelope = false;
        }
    }

    /**
     * Применение изменения наименования произвольного документа в группе "Прочие"
     */
    public applyDocumentName(value) {
        if (value) {
            this.data.name = value;
        }
        this.isProcessEditDocumentName = false;
        this.isProcessSimplifyAddDocumentToComplex = false;
        this.isProcessSelectDocumentKind = false;
        if (this.data.docCode) {
            const find = this.documentsForComplex.find(item => item.code === this.data.docCode);
            if (find && find.name !== this.data.name) {
                this.data.docCode = null;
                this.selectedDocumentFromList = null;
            }
        }
    }

    /**
     * Обновление информации о запросе
     */
    public updateEnvelopeData() {
        this.commonAppealSaveService.updateEnvelopeData(this.document);
    }

    public typedDocumentName(value) {
        this.data.name = value;
    }

    public fileChange(event) {
        const filesList = event.target.files;
        if (filesList.length > 0) {
            Array.from(filesList).forEach(file => {
                this.data.queue.push(file);
            });

            this.commonAppealSaveService.saveAppeal();
        }
    }

    public afterDeleteFile() {
        this.commonAppealSaveService.saveAppeal(null, { isNotSave: true });
    }

    public checkallowEdit(event) {
        this.isCheckFileSign.emit(event);
    }

    public inspectorChange(event) {
        this.commonAppealSaveService.saveAppeal(null, { isNotSave: true });
    }
}
